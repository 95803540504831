import React from "react";
import styles from './GenericProductFilterContainer.module.scss'

export default function GenericProductFilterContainer({ children, clearFilters }) {
  return (
    <div className={styles['filters']}>
      <div className={styles['filter-title']}>
        {/* <i className="fas fa-caret-up"></i> */}
        <div className={styles['title']}>Filters</div>
        <div className={styles['clear']} onClick={clearFilters}>
          Clear All
        </div>
      </div>
      {children}
    </div>
  );
}
