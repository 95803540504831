import React, { Component } from 'react'
import Navigation from '../../../components/navigation/navigation'
import { ReactComponent as HouseIcon } from '../../../assets/House Icon.svg'
import KpiCard from '../../../components/kpi-card/kpiCard'
import Container from '../../../components/container/container'
import {
    BarChart,
    XAxis,
    Bar,
    YAxis,
    // Tooltip,
    Legend,
    ResponsiveContainer,
    CartesianGrid,
} from 'recharts'
import LargeLinkButton from '../../../components/large-link-button/largeLinkButton'
import Footer from '../../../components/footer/footer'
import request from '../../../helpers/request'
import endpoints from '../../../helpers/endpoints'
import { view } from '@risingstack/react-easy-state'
import S3Modal from '../../../components/s3Modal/S3Modal'
import session from '../../../stores/session';
import useWSProduct from '../../../helpers/useWSProduct'
import PageTitle from '../../../components/page-header/pageTitle'
import ProductChartInfo from '../../../components/product-chart-info/product-info-chart'

export default view(
    class ContactValidation extends Component {
        constructor(props) {
            super(props)

            
            this.state = {
                stats: {
                    checked_in: {
                        month: null,
                        number: null,
                    },
                    do_not_exclude: {
                        number: null,
                        percent: null,
                    },
                    ex_directory: {
                        number: null,
                        percent: null,
                    },
                    exclude: {
                        number: null,
                        percent: null,
                    },
                    tps: {
                        number: null,
                        percent: null,
                    },
                },
                last12months: [],
                last12files: [],
                files: [],
                modalIsOpen: false,
                modalMode: null,
                modalMessage: null,
                translations: null,
            }
        }
        
        componentDidMount() {
            useWSProduct('contact_validation')// eslint-disable-line
            request(true)
            .get(endpoints.CONTACT_VALIDATION)
            .then((e) => {
                let last12files = e.data.twelve_files_chart
                if (last12files.length < 12) {
                        last12files = [
                            ...last12files,
                            ...Array(12-last12files.length).fill({})
                        ]
                    }
                    this.setState({
                        stats: e.data.stats,
                        last12months: e.data.twelve_months_table,
                        last12files
                    })
                })
                .catch((e) => {
                    console.error(e.response)
                })

            this.loadTranslations();
        }

        componentWillUnmount() {
            useWSProduct('contact_validation', 'unlisten')// eslint-disable-line
        }

        loadTranslations = () => {
            request(true).get('/translate?key=cv.').then(r => {
                this.setState({
                    translations: r.data
                });
            }).catch(e => {
                console.error(e);
            })
        }

        openUploadModal = () => {
            this.setState({
                modalIsOpen: true,
                modalMode: 'upload',
            })
        }

        openDownloadModal = () => {
            this.setState({
                modalIsOpen: true,
                modalMode: 'download',
            });
        }

        closeModal = () => {
            this.setState({
                modalIsOpen: false,
            })
        }

        render() {
            return (
                <div className="grid grid-gap-15" id="contact-validation-page">
                    <Navigation />
                    <Container>
                        <PageTitle title="Contact Validation" />
                    </Container>
                    <Container>
                        <div className="grid grid-columns-5 grid-gap-15">
                            { this.state.stats.checked_in && this.state.stats.checked_in.number !== null ? (
                                <>
                                    <KpiCard
                                        primary={parseInt(this.state.stats.checked_in.number).toLocaleString()}
                                        title={'Checked in ' + this.state.stats.checked_in.month}
                                    />
                                    <KpiCard
                                        primary={parseInt(this.state.stats.do_not_exclude.number).toLocaleString()}
                                        title={ this.state.translations ? this.state.translations['cv.DoNotExclude'] : '' }
                                        secondary={this.state.stats.do_not_exclude.percent}
                                    />
                                    <KpiCard
                                        primary={parseInt(this.state.stats.exclude.number).toLocaleString()}
                                        title={ this.state.translations ? this.state.translations['cv.Exclude'] : '' }
                                        secondary={this.state.stats.exclude.percent}
                                    />
                                    <KpiCard
                                        primary={parseInt(this.state.stats.ex_directory.number).toLocaleString()}
                                        title={ this.state.translations ? this.state.translations['cv.ExDirectory'] : '' }
                                    />
                                    <KpiCard
                                        primary={parseInt(this.state.stats.tps.number).toLocaleString()}
                                        title={ this.state.translations ? this.state.translations['cv.TPSRegistered'] : '' }
                                    />
                                </>
                            ) : (
                                    <></>
                                )}
                        </div>
                        <hr
                            style={{ width: '100%', opacity: '25%', margin: '25px 0 25px 0' }}
                        />
                        <div className="grid grid-columns-2 grid-gap-20">
                            <div className="grid grid-gap-20">
                                <div className="grid">
                                    <p className="chart-title">Last 12 files performance</p>
                                    {/* Chart */}
                                    <div className="chart-wrapper">
                                        <ResponsiveContainer minHeight="400px" maxHeight="500px">
                                            <BarChart
                                                data={this.state.last12files}
                                                barSize={20}
                                                backgroundColor="#fff"
                                                margin={{ top: 20, bottom: 20, right: 5, left: 5}}
                                            >
                                                <XAxis
                                                    dataKey="Title"
                                                    type="category"
                                                    minTickGap={1}
                                                    interval={0}
                                                    tick={{fontSize: 14, color: 'rgb(204, 204, 204)'}}
                                                    height={60}
                                                    tickMargin={20}
                                                    tickLine={false}
                                                    stroke="rgb(204, 204, 204)"
                                                />
                                                <YAxis
                                                    axisLine={false}
                                                    unit="%"
                                                    ticks={[0,20,40,60,80,100]}
                                                    tickMargin={10}
                                                    tickLine={false}
                                                    tick={{ color: 'rgb(204, 204, 204)' }}
                                                    stroke="rgb(204, 204, 204)"
                                                />

                                                <CartesianGrid vertical={false}/>

                                                {/* <Tooltip /> */}

                                                <Legend align="left" iconType="circle" iconSize={11} />

                                                <Bar
                                                    dataKey="Invalid"
                                                    stackId="primary"
                                                    fill="#445469"
                                                />
                                                <Bar
                                                    dataKey="Ex-Dir"
                                                    stackId="primary"
                                                    fill="var(--primary)"
                                                />
                                                <Bar dataKey="TPS" stackId="primary" fill="#0d73b2" />
                                                <Bar
                                                    dataKey="Do not Exclude"
                                                    stackId="primary"
                                                    fill="#f19a14"
                                                />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                                <div className="grid grid-columns-2 grid-gap-10">
                                    { session.user.permissions.indexOf('CV-DOWNLOAD') !== -1 ?
                                        <LargeLinkButton
                                            callback={this.openDownloadModal}
                                            title="Download Return File"
                                            icon={ 'fas fa-cloud-download-alt' }
                                        /> : null }
                                    { session.user.permissions.indexOf('CV-UPLOAD') !== -1 ?
                                        <LargeLinkButton
                                            callback={this.openUploadModal}
                                            title="Issue Input File"
                                            icon={ 'fas fa-cloud-upload-alt' }
                                        /> : null }
                                </div>
                            </div>
                            <div>
                                <p style={{display: 'flex', justifyContent: 'space-between'}} className="chart-title">Last 12 months performance <ProductChartInfo path={window.location.pathname} style={{margin: 0, cursor: 'pointer', color: 'var(--primary)', paddingLeft: '15px'}} /></p>
                                {/* Table */}
                                <table className="table borders">
                                    <thead>
                                        <tr>
                                            <th>{ this.state.translations ? this.state.translations['cv.Month'] : '' }</th>
                                            <th>{ this.state.translations ? this.state.translations['cv.Checked'] : '' }</th>
                                            <th>{ this.state.translations ? this.state.translations['cv.DoNotExclude'] : '' }</th>
                                            <th>{ this.state.translations ? this.state.translations['cv.Exclude'] : '' }</th>
                                            <th>{ this.state.translations ? this.state.translations['cv.ExDir'] : '' }</th>
                                            <th>{ this.state.translations ? this.state.translations['cv.TPS'] : '' }</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.last12months.map(function (data) {
                                            return (
                                                <tr key={data.month}>
                                                    <td>{data.month}</td>
                                                    <td>{parseInt(data.checked).toLocaleString()}</td>
                                                    <td>{parseInt(data.do_not_exclude).toLocaleString()}</td>
                                                    <td>{parseInt(data.exclude).toLocaleString()}</td>
                                                    <td>{parseInt(data.ex_dir).toLocaleString()}</td>
                                                    <td>{parseInt(data.tps).toLocaleString()}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Container>
                    <S3Modal showModal={this.state.modalIsOpen} closeModal={this.closeModal} modalMode={this.state.modalMode} listingUrl={endpoints.CONTACT_VALIDATION_INDEX} uploadUrl={endpoints.CONTACT_VALIDATION_UPLOAD}/>
                    <Footer />
                </div>
            )
        }
    },
)
