import React, { Component, useEffect } from 'react'
import Navigation from '../../../components/navigation/navigation'
import { ReactComponent as HouseIcon } from '../../../assets/House Icon.svg'
import Container from '../../../components/container/container'
import Footer from '../../../components/footer/footer'
import request from '../../../helpers/request'
import endpoints from '../../../helpers/endpoints'
import { view } from '@risingstack/react-easy-state'
import './proprietorValidation.scss';

import { Link, withRouter } from 'react-router-dom'
import session from '../../../stores/session'
import HelpVideo from '../../../components/help-video/helpVideo'
import useWSProduct from '../../../helpers/useWSProduct'
import PropertyMap from '../../../components/property-map/property-map'
import { CircularProgress } from '@mui/material'
import PageTitle from '../../../components/page-header/pageTitle'
import ProductIcons from '../../../helpers/productIcons'

export default view(
    withRouter(
        class ProprietorValidationSearch extends Component {
            constructor(props) {
                super(props)

                this.state = {
                    accountNumber: '',
                    customer: null,
                    showMap: true,
                    purchasable: null,
                    shouldRefreshMap: false,
                    translations: null,
                    loading: false,
                    selectedDeed: {},
                    customers: [{}, {}, {}], 
                }
            }

            componentDidMount = () => {
                useWSProduct('proprietor_validation');// eslint-disable-line
                request(true).get('/translate?key=proprietor-validation.').then(r => {
                    this.setState({
                        translations: r.data
                    });
                }).catch(e => {
                    console.error(e);
                })
            }

            accountNumberChange = (event) => {
                this.setState({
                    accountNumber: event.target.value
                });
            }

            getData = () => {
                this.setState({ loading: true, selectedCustomer: null });
                request(true).get(`${endpoints.PROPRIETOR_VALIDATION_GET_CUSTOMER}?search=${this.state.accountNumber}`).then(r => {
                    if (!r.data.customers.length) window.alert('No Results Found.');
                    if (!Array.isArray(r.data.customers)) r.data.customers = [r.data.customers];
                    while (r.data.customers.length < 3) { r.data.customers.push({})}
                    this.setState({
                        customers: r.data.customers,
                    })
                    const purchasedCustomer = r.data.customers.find(customer => customer.Date_Bought != null)
                    purchasedCustomer ? 
                        this.setState({
                            customer: purchasedCustomer,
                            selectedDeed: {Deed_TitleNo: purchasedCustomer.Deed_TitleNo, File_Date: purchasedCustomer.File_Date},
                            showMap: true,
                            shouldRefreshMap: true,
                        }, () => {
                            this.setState({ loading: false });
                            this.setState({ shouldRefreshMap: false });
                        })
                    :
                        this.setState({
                            customer: r.data.customers[0],
                            showMap: true,
                            shouldRefreshMap: true,
                        }, () => {
                            this.setState({ loading: false });
                            this.setState({ shouldRefreshMap: false });
                        });
                }).catch(e => {
                    window.alert('No Results Found.')
                    this.setState({ loading: false });
                    console.log(e);
                });
            }

            purchaseCustomer = (Deed_TitleNo) => {
                this.setState({ loading: true });
                request(true).post(`${endpoints.PROPRIETOR_VALIDATION_BUY_CUSTOMER}`, {
                    "customer": this.state.accountNumber,
                    "Deed_TitleNo": Deed_TitleNo
                }).then(r => {
                    const customers = [...this.state.customers];
                    const index = customers.findIndex((obj) => obj.Deed_TitleNo === Deed_TitleNo);
                    customers[index] = r.data.customer;
                    this.setState({ 
                        customers: customers,
                        selectedDeed: {Deed_TitleNo: r.data.customer.Deed_TitleNo, File_Date: r.data.customer.File_Date},
                        loading: false
                    });
                }).catch(e => {
                    this.setState({ loading: false });
                    console.log(e);
                    window.alert("Purchase failed.");
                })
            }

            searchKeyDown = (e) => {
                if (e.which === 13) {
                    this.getData();
                }
            }

            componentWillUnmount() {
                useWSProduct('proprietor_validation', 'unlisten');// eslint-disable-line
            }

            render() {
                return (
                    <div className="grid grid-gap-15" id="proprietor-validation-page">
                        <Navigation/>
                        <Container>
                            <PageTitle title="Proprietor Validation" iconClass={ProductIcons.BusinessIcon.props.className} titleRight={
                                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    {
                                    this.state.loading ? <CircularProgress size={22} /> : null
                                    }
                                    {/* <Link className="navigate" to="/portal/proprietor-validation/">
                                        To Overview
                                    </Link>
                                    { (session.user.permissions.indexOf('PROPRIETOR_VALIDATION-WORKFLOW') != -1) ?
                                        <Link className="navigate" to="/portal/proprietor-validation/workflow">
                                            To Workflow
                                        </Link>
                                    : null } */}
                                </div>
                            } />
                        </Container>
                        <Container>
                            <div className="proprietor-validation">
                                <div className="primary-grid">
                                    <div className="left-grid">
                                        <div className="data-row">
                                            <div className="data-row-title">
                                            { this.state.translations ? this.state.translations['proprietor-validation.AccountNumber'] : '' }
                                            </div>
                                            <input disabled={this.state.loading} type="text" className="data-row-field" name="account-number" value={ this.state.accountNumber } onChange={ this.accountNumberChange } onKeyPress={ this.searchKeyDown }/>
                                        </div>
                                        { this.state.customer && this.state.customer.Surname !== false ?
                                            this.state.customer ? this.state.customer.Deed_Surname ? <>
                                            <div className="name-grid">
                                                <div className="data-row">
                                                    <div className="data-row-title">
                                                    Title
                                                    </div>
                                                    <input type="text" className="data-row-field" disabled={true} value={ this.state.customer && this.state.customer.Deed_Title ? `${this.state.customer.Deed_Title}` : '' }/>
                                                </div>
                                                <div className="data-row">
                                                    <div className="data-row-title">
                                                    Forename
                                                    </div>
                                                    <input type="text" className="data-row-field" disabled={true} value={ this.state.customer && this.state.customer.Deed_Forename ? `${this.state.customer.Deed_Forename}` : '' }/>
                                                </div>
                                                <div className="data-row">
                                                    <div className="data-row-title">
                                                    Surname
                                                    </div>
                                                    <input type="text" className="data-row-field" disabled={true} value={ this.state.customer && this.state.customer.Deed_Surname ? `${this.state.customer.Deed_Surname}` : '' }/>
                                                </div>
                                            </div>
                                            </> : <>
                                                <div className="data-row">
                                                    <div className="data-row-title">
                                                        { this.state.translations ? this.state.translations['proprietor-validation.CompanyName'] : '' }
                                                    </div>
                                                    <input type="text" className="data-row-field" disabled={true} value={ this.state.customer && this.state.customer.Company_Name ? `${this.state.customer.Company_Name}` : '' }/>
                                                </div>
                                            </> : null
                                        :
                                            <div className="data-row">
                                                <div className="data-row-title">
                                                    { this.state.translations ? this.state.translations['proprietor-validation.CompanyName'] : '' }
                                                </div>
                                                <input type="text" className="data-row-field" disabled={true} value={ this.state.customer && this.state.customer.Company_Name ? `${this.state.customer.Company_Name}` : '' }/>
                                            </div>
                                        }
                                        <div className="data-row">
                                            <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['proprietor-validation.ClientAddress'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" disabled={true} value={ this.state.customer && this.state.customer.SPID_Address ? this.state.customer.SPID_Address : '' }/>
                                        </div>
                                        <div className="data-row">
                                            <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['proprietor-validation.MatchedAddress'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" disabled={true} value={ this.state.customer && this.state.customer.AddressBase_Address ? this.state.customer.AddressBase_Address : '' }/>
                                        </div>
                                        <div className="left-grid-bottom">
                                            <div className="data-row">
                                                <div className="data-row-title">
                                                    { this.state.translations ? this.state.translations['proprietor-validation.UPRN'] : '' }
                                                </div>
                                                <input type="text" className="data-row-field" disabled={true} value={ this.state.customer && this.state.customer.UPRN ?  this.state.customer.UPRN : ''}/>
                                            </div>
                                            <div className="data-row">
                                                <div className="data-row-title">
                                                    { this.state.translations ? this.state.translations['proprietor-validation.VOA'] : '' }
                                                </div>
                                                <input type="text" className="data-row-field" disabled={true} value={ this.state.customer && this.state.customer.AD_VOA_No ? this.state.customer.AD_VOA_No : '' }/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="right-grid">
                                        <button className="search-button" onClick={ this.getData }>
                                            Search
                                        </button>
                                        <div className="data-row">
                                            <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['proprietor-validation.AddressStatus'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" disabled={true} value={this.state.customer && this.state.customer.Address_Score ? this.state.customer.Address_Score : '' }/>
                                        </div>
                                        <div className="data-row">
                                            <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['proprietor-validation.DeedCount'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" disabled={true} value={this.state.customer && this.state.customer.deed_count ? this.state.customer.deed_count : ''}/>
                                        </div>
                                        <div className="data-row">
                                            <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['proprietor-validation.DeedsFound'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" disabled={true} value={this.state.customer && this.state.customer.Deeds_Found ? this.state.customer.Deeds_Found : ''}/>
                                        </div>
                                        <div className="data-row">
                                            <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['proprietor-validation.OnCCOD'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" disabled={true} value={ this.state.customer && this.state.customer.On_CCOD ? this.state.customer.On_CCOD : '' }/>
                                        </div>
                                    </div>
                                </div>
                                <div className="secondary-grid">
                                    <div style={{display: 'flex', flexDirection: 'row', gap: '.5em', height: '100%'}}>
                                        <table className={'table borders squish smaller-text'} style={{ height: '100%'}}>
                                            <thead>
                                                <tr>
                                                    <th style={{width: "150px"}}></th>
                                                    { this.state.customers.map(customer => {
                                                        return (
                                                            <th style={{width: '27%'}}>
                                                                { (session.user.permissions.indexOf('PROPRIETOR_VALIDATION-PURCHASE') != -1 && customer.Deed_Status == "Deed Available") ?
                                                                        <button style={{display: 'inline-block', marginRight: '10px', cursor: 'pointer'}} className="buy-button" disabled={customer.Date_Bought ? true : false} onClick={() => this.purchaseCustomer(customer.Deed_TitleNo)}>
                                                                            {
                                                                                    'Buy'
                                                                            }
                                                                        </button>
                                                                    :
                                                                        <button style={{display: 'inline-block', marginRight: '10px', cursor: 'pointer'}} className="buy-button disabled" disabled>
                                                                            Buy
                                                                        </button>
                                                                }
                                                                { (customer.Date_Bought || customer.Bought_By) ? 
                                                                    <button style={{display: 'inline-block', cursor: 'pointer'}} className="buy-button" onClick={ () => { this.setState({selectedDeed: {Deed_TitleNo: customer.Deed_TitleNo, File_Date: customer.File_Date} })}}>View</button>
                                                                :
                                                                    <button style={{display: 'inline-block', cursor: 'pointer'}} className="buy-button disabled" disabled={true}>View</button>
                                                                }
                                                            </th>
                                                        )
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>Tenure</td>
                                                    { this.state.customers.map(customer => {
                                                        return <td>{ customer.Deed_Type}</td>
                                                    })}
                                                </tr>
                                                <tr>
                                                    <td>Title Number</td>
                                                    { this.state.customers.map(customer => {
                                                        return <td>{ customer.Deed_TitleNo}</td>
                                                    })}
                                                </tr>
                                                <tr>
                                                    <td>Registered Occupier</td>
                                                    { this.state.customers.map(customer => {
                                                        return <td>{ customer.Registered_Occupier}</td>
                                                    })}
                                                </tr>
                                                <tr>
                                                    <td>Search Address</td>
                                                    { this.state.customers.map(customer => {
                                                        return <td>{ customer.Search_Address}</td>
                                                    })}
                                                </tr>
                                                <tr>
                                                    <td>Matched Address</td>
                                                    { this.state.customers.map(customer => {
                                                        return <td>{ customer.Deed_Address}</td>
                                                    })}
                                                </tr>
                                                <tr>
                                                    <td>Address Match</td>
                                                    { this.state.customers.map(customer => {
                                                        return <td>{ customer.Address_Match}</td>
                                                    })}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <DeedPDFViewer customers={this.state.customers} refresh={this.getData} selectedDeed={this.state.selectedDeed} />
                                </div>
                            </div>
                        </Container>
                        <HelpVideo video={session.company.vca_video_url}/>
                        <Footer />
                    </div>
                )
            }
        }
    )
)

export function DeedPDFViewer({ customers, refresh, selectedDeed = {} }) {
    useEffect(() => {
        if (!customers) return;
        useWSProduct('proprietor_validation', 'listen', `_${customers[0].Account_Number}`, () => {// eslint-disable-line
            refresh()
        })

        return () => {
            // cleanup
            useWSProduct('proprietor_validation', 'unregister', `_${customers[0].Account_Number}`)// eslint-disable-line
        }
    })

    const selectedCustomer = customers.find(customer => customer.Deed_TitleNo === selectedDeed.Deed_TitleNo && customer.File_Date === selectedDeed.File_Date)
    return (
        selectedCustomer && selectedCustomer.Bought_By ? selectedDeed.Deed_TitleNo ? <div>
            <iframe title="Deed PDF" style={{minHeight: 400, minWidth: '100%'}} src={selectedCustomer.DeedPDF} />
        </div> : <div style={{height: '100%', minHeight: 400, backgroundColor: 'lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <p style={{color: 'white', fontWeight: 700}}>We're processing your deed purchase, it'll appear here automatically once available.</p>
        </div> : <div style={{height: '100%', minHeight: 400, backgroundColor: 'lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <p style={{color: 'white', fontWeight: 700}}>Deed available after purchase.</p>
        </div>
    )
}