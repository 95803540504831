import React from 'react'
import { useRef, useState, useEffect, useCallback } from 'react';
import endpoints from '../../../helpers/endpoints';
import request from '../../../helpers/request';

export function GenericTable({ 
    data, rows, title, 
    extraClass, style={}, evidence = true, 
    subtitle = 'Evidence', align, 
    firstColWidth, biggerSecondRow = true,
    leftOfTitleContent, setCustomerNumber = null
 }) {
  return (
    <table className={`table borders ${extraClass}`} style={{ height: '100%', ...style }}>
      <thead>
        <tr>
            {
                leftOfTitleContent ? <>
                    <th>
                        {leftOfTitleContent}
                    </th>
                </> : null
            }
          <th style={{textAlign: leftOfTitleContent ? 'center' : 'start' }} colSpan={evidence ? 1 : 2}>
            {title}
          </th>
          {
            evidence ? <>
              <th>
                {subtitle}
              </th>
            </> : null
          }
        </tr>
      </thead>
      <tbody>
        {
          rows.map((row, i) => {
            const Value = row[1]
            if (row[0] === "Phone") {
                return (
                <tr key={`gtable_${Math.random()}_${i}`}>
                    <td style={{ height: biggerSecondRow ? i === 1 ? 30 : undefined : undefined, textAlign: align, width: i === 0 ? firstColWidth || undefined : undefined }}>{row[0]}</td>
                    <td style={{ minWidth: evidence ? 'unset' : 60 }}>{data ? (typeof Value === 'string') ?  data[Value] : <Value data={data}></Value> : null} { data ? data[Value] ? <i onClick={() => setCustomerNumber(data[Value])} className="fa-solid fa-phone" style={{color: "var(--primary)", paddingLeft: "10px", cursor: 'pointer'}}></i> : null : null }</td>
                </tr>
                )
            } else if (row[0] === "Website") {
                return (
                    <tr key={`gtable_${Math.random()}_${i}`}>
                      <td style={{ height: biggerSecondRow ? i === 1 ? 30 : undefined : undefined, textAlign: align, width: i === 0 ? firstColWidth || undefined : undefined }}>{row[0]}</td>
                      <td style={{ minWidth: evidence ? 'unset' : 60 }}>{data ? (typeof Value === 'string') ?  
                            <a style={{color: 'var(--blue)', textDecoration: 'none'}} href={data[Value] && !data[Value].includes('http://') && !data[Value].includes('https://') ? "https://" + data[Value] : data[Value]} target='_blank'>{data[Value]}</a> 
                        : 
                            <Value data={data}></Value> : null}</td>
                    </tr>
                  )
            } else {
                return (
                  <tr key={`gtable_${Math.random()}_${i}`}>
                    <td style={{ height: biggerSecondRow ? i === 1 ? 30 : undefined : undefined, textAlign: align, width: i === 0 ? firstColWidth || undefined : undefined }}>{row[0]}</td>
                    <td style={{ minWidth: evidence ? 'unset' : 60 }}>{data ? (typeof Value === 'string') ?  data[Value] : <Value data={data}></Value> : null}</td>
                  </tr>
                )
            }
          })
        }
      </tbody>
    </table>
  )
}

export function DVSAEvidence({data, setCustomerNumber}) {
    const rows = [['Company', 'DVSA_Name'],['Address', 'DVSA_Address'],['Phone', 'DVSA_Phone']];
    return (
        <GenericTable data={data} rows={rows} title="DVSA" setCustomerNumber={setCustomerNumber} />
    )
}

export function CharityCommisionEvidence({data, setCustomerNumber}) {
    const rows = [['Name', 'CC_Name'],['Address', 'CC_Address'],['Phone', 'CC_Phone']];
    return (
        <GenericTable data={data} rows={rows} title="Trip Advisor" setCustomerNumber={setCustomerNumber} />
    )
}

export function BTDirectoryEvidence({data, setCustomerNumber}) {
    const rows = [['Company', 'BT_Name'],['Address', 'BT_Address'],['Phone', 'BT_Phone']];
    return (
        <GenericTable data={data} rows={rows} title="BT Directory" setCustomerNumber={setCustomerNumber} />
    )
}

export function FoodStandardsEvidence({data, setCustomerNumber}) {
    const rows = [['Company', 'FSA_Name'],['Address', 'FSA_Address'],['Rating Date', 'FSA_Rating_Date']];
    return (
        <GenericTable data={data} rows={rows} title="Food Standards Agency" setCustomerNumber={setCustomerNumber} />
    )
}

export function GoogleEvidence({data, setCustomerNumber}) {
    const rows = [['Company','Google_Company'],['Address','Google_Address'],['Business Status', 'Google_Status'],['Phone','Google_Phone'],['First Review Date','Google_First_Date'],['Last Review Date','Google_Date']];
    return (
        <GenericTable data={data} rows={rows} title="Google" setCustomerNumber={setCustomerNumber} />
    )
}

export function CompaniesHouseEvidence({data, setCustomerNumber}) {
    const rows = [['Company','Comp_Hse_Company'],['Address','Comp_Hse_Address'],['Business Status','Comp_Hse_Status'],['SIC','Comp_Hse_SIC'],['Incorporated Date','Comp_Hse_Inc_Date'],['Last Statement Date','Comp_Hse_LS_Date']];
    return (
        <GenericTable data={data} rows={rows} title="Companies House" setCustomerNumber={setCustomerNumber} />
    )
}

export function YellComEvidence({data, setCustomerNumber}) {
    const rows = [['Company','Yell_Company'],['Address','Yell_Address'],['Business Status','Yell_Status'],['Phone','Yell_Phone'],['Website','Yell_Website'],['Email','Yell_Email']];
    return (
        <GenericTable data={data} rows={rows} title="Yell.Com" setCustomerNumber={setCustomerNumber} />
    )
}

export function AddressEvidence({data, setCustomerNumber}) {
    const rows = [['Company','AD_Name'],['UPRN Address','AddressBase_Address'],['UPRN','UPRN'],['VOA Number','AD_VOA_No'],['VOA End Date','AD_VOA_End'],['CT Start Date','AD_CT_Start']];
    return (
        <GenericTable data={data} rows={rows} title="Address Data" setCustomerNumber={setCustomerNumber} />
    )
}

export function CQCEvidence({data, setCustomerNumber}) {
    const rows = [['Company', 'CQC_Name'],['Address', 'CQC_Address'],['Phone', 'CQC_Phone']];
    return (
        <GenericTable data={data} rows={rows} title="Quality Care Comm" setCustomerNumber={setCustomerNumber} />
    )
}

export function JustEatEvidence({data, setCustomerNumber}) {
    const rows = [['Company', 'JE_Company'],['Address', 'JE_Address'],['Phone', 'JE_Phone']];
    return (
        <GenericTable data={data} rows={rows} title="Just Eat" setCustomerNumber={setCustomerNumber} />
    )
}

export function One92Evidence({data, setCustomerNumber}) {
    const rows = [['Company', 'One92_Company'],['Address', 'One92_Address'],['Phone', 'One92_Phone']];
    return (
        <GenericTable data={data} rows={rows} title="192.com" setCustomerNumber={setCustomerNumber} />
    )
}

export function FacebookEvidence({data, setCustomerNumber}) {
    const rows = [['Company', 'FB_Company'],['Address', 'FB_Address'],['Phone', 'FB_Phone']];
    return (
        <GenericTable data={data} rows={rows} title="Facebook" setCustomerNumber={setCustomerNumber} />
    )
}

export function MDS({data}) {
    return (
        <table className={`table borders`} style={{ tableLayout: 'fixed' }}>
        <thead>
          <tr>
            <th style={{textAlign: 'start' }} colSpan= '1' >
                SPID
                </th>
            <th style={{textAlign: 'start' }} colSpan= '1' >
                Customer Name
            </th>
            <th style={{textAlign: 'start' }} colSpan= '1' >
                Customer Banner Name
            </th>            
            <th style={{textAlign: 'start' }} colSpan= '1' >
                Address Description
            </th>
            <th style={{textAlign: 'start' }} colSpan= '1' >
                Secondary Address
            </th>
            <th style={{textAlign: 'start' }} colSpan= '1' >
                Primary Address
            </th>
            <th style={{textAlign: 'start' }} colSpan= '1' >
                Address - Line 1
            </th>
            <th style={{textAlign: 'start' }} colSpan= '1' >
                Address - Line 2
            </th>
            <th style={{textAlign: 'start' }} colSpan= '1' >
                Address - Line 3
            </th>
            <th style={{textAlign: 'start' }} colSpan= '1' >
                Address - Line 4
            </th>
            <th style={{textAlign: 'start' }} colSpan= '1' >
                Address - Line 5
            </th>
            <th style={{textAlign: 'start' }} colSpan= '1' >
                Address - Postcode
            </th>
          </tr>
        </thead>
        <tbody>
            {
            data.length ?  
                data.length > 0 ? 
                    data.map(row => {
                        if (!row) return
                        return (
                            <tr>
                                <td>{row.SPID}</td>
                                <td>{row.Customer_Name}</td>
                                <td>{row.Customer_Banner_Name}</td>
                                <td>{row.Premises_Address_Free_Desc}</td>
                                <td>{row.Premises_Address_Secondary_Address_Obj}</td>
                                <td>{row.Premises_Address_Primary_Address_Obj}</td>
                                <td>{row.Premises_Address_Address_Line_1}</td>
                                <td>{row.Premises_Address_Address_Line_2}</td>
                                <td>{row.Premises_Address_Address_Line_3}</td>
                                <td>{row.Premises_Address_Address_Line_4}</td>
                                <td>{row.Premises_Address_Address_Line_5}</td>
                                <td>{row.Premises_Address_Postcode}</td>
                            </tr>
                        )
                    }) 
                : 
                    null
            :
                <tr>
                    <td>{data.SPID}</td>
                    <td>{data.Customer_Name}</td>
                    <td>{data.Customer_Banner_Name}</td>
                    <td>{data.Premises_Address_Free_Desc}</td>
                    <td>{data.Premises_Address_Secondary_Address_Obj}</td>
                    <td>{data.Premises_Address_Primary_Address_Obj}</td>
                    <td>{data.Premises_Address_Address_Line_1}</td>
                    <td>{data.Premises_Address_Address_Line_2}</td>
                    <td>{data.Premises_Address_Address_Line_3}</td>
                    <td>{data.Premises_Address_Address_Line_4}</td>
                    <td>{data.Premises_Address_Address_Line_5}</td>
                    <td>{data.Premises_Address_Postcode}</td>
                </tr>
            }
        </tbody>
      </table>
    )
}

export function TitleOwnerRow({ state }) {
    return (
        <div style={{gridColumnStart: 1, gridColumnEnd: 3}}>
            <div className="left-grid-bottom" style={{display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 5}}>
                <div className="data-row">
                    <div className="data-row-title">
                    { state.translations ? state.translations['otv.title-number-owner'] : '' }
                    </div>
                    <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.LR_Name_No ? `${state.customer.LR_Name_No}` : '' : '' }/>
                </div>
                <div className="data-row">
                    <div className="data-row-title">
                    { state.translations ? state.translations['otv.title-owner-address'] : '' }
                    </div>
                    <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.LR_Address ? `${state.customer.LR_Address}` : '' : '' }/>
                </div>
            </div>
        </div>
    )
}

export function LeftColumn({ state, children, setCustomerNumber }) {
    return (
        <>
            {
                children
            }
            <div className="data-row">
                <div className="data-row-title">
                    { state.translations ? state.translations['otv.client_address'] : '' }
                </div>
                <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.SPID_Address : '' }/>
            </div>
            <div className="data-row">
                <div className="data-row-title">
                    { state.translations ? state.translations['otv.cmos_uprn_address'] : '' }
                </div>
                <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.CMOS_UPRN_Single_Line ? state.customer.CMOS_UPRN_Single_Line : '' : ''}/>
            </div>
            <div className="data-row">
                <div className="data-row-title">
                    { state.translations ? state.translations['otv.cmos_voa_address'] : '' }
                </div>
                <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.CMOS_VOA_Single_Line ? state.customer.CMOS_VOA_Single_Line : '' : '' }/>
            </div>
            <div className="data-row">
                <div className="data-row-title">
                    { state.translations ? state.translations['otv.matched_address'] : '' }
                </div>
                <div style={{display: 'grid', cursor: 'pointer'}} onClick={(e) => {window.blur(); if (state.customer?.AddressBase_Address) window.open(`https://www.google.com/maps/search/?api=1&query=${state.customer ? state.customer.AddressBase_Address : ''}`)}} >
                    <input style={{cursor: 'pointer'}} disabled={true} type="text" className="data-row-field colour-blue" value={ state.customer ? state.customer.AddressBase_Address : '' }/>
                </div>
            </div>
            <div className="left-grid-bottom">
            <div className="data-row" style={{gridColumnStart: 1, gridColumnEnd: 3}}>
                    <div className="data-row-title">
                    { state.translations ? state.translations['otv.company_name'] : '' }
                    </div>
                    <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.Company_Name ? `${state.customer.Company_Name}` : '' : '' }/>
                </div>
                <div className="data-row">
                    <div className="data-row-title">
                    { state.translations ? state.translations['otv.company_number'] : '' }
                    </div>
                    <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.Company_Number ? `${state.customer.Company_Number}` : '' : '' }/>
                </div>
            </div>
            <div className="left-grid-bottom"> 
                <div className="data-row">
                    <div className="data-row-title">
                    { state.translations ? state.translations['otv.website'] : '' }
                    </div>
                    <div className="data-row-field input-like-div">{ state.customer && state.customer.Website && ( 
                        <a style={{color: 'var(--blue)', textDecoration: 'none'}} href={state.customer.Website && !state.customer.Website.includes('http://') && !state.customer.Website.includes('https://') ? "https://" + state.customer.Website : state.customer.Website} target='_blank'>{state.customer.Website} </a> 
                    )}</div>
                </div>
                <div className="data-row">
                    <div className="data-row-title">
                    { state.translations ? state.translations['otv.landline'] : '' }
                    </div>
                    <div className="data-row-field input-like-div">{ state.customer && state.customer.Landline && ( <>{state.customer.Landline} <i onClick={() => setCustomerNumber(state.customer.Landline)} className="fa-solid fa-phone" style={{color: "var(--primary)", paddingLeft: "10px", cursor: 'pointer'}}></i></> )}</div>
                </div>
                <div className="data-row">
                    <div className="data-row-title">
                    { state.translations ? state.translations['otv.mobile'] : '' }
                    </div>
                    <div className="data-row-field input-like-div">{ state.customer && state.customer.Mobile && ( <>{state.customer.Mobile} <i onClick={() => setCustomerNumber(state.customer.Mobile)} className="fa-solid fa-phone" style={{color: "var(--primary)", paddingLeft: "10px", cursor: 'pointer'}}></i></> )}</div>
                </div>
            </div>
        </>
    )
}

export function RightColumn({ state, children }) {
    return (
        <div className="right-grid">
            {
                children
            }
            {/* <div className="data-row">
                <div className="data-row-title">
                    { state.translations ? state.translations['otv.type'] : '' }
                </div>
                <input type="text" className="data-row-field" disabled={true} value={state.customer ? state.customer.Type : ''}/>
            </div> */}
            <div className="data-row">
                <div className="data-row-title">
                    { state.translations ? state.translations['otv.void_age_band'] : '' }
                </div>
                <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.Void_Age_Band : '' }/>
            </div>
            <div className="data-row">
                <div className="data-row-title">
                    Assigned to
                </div>
                <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.assigned_to : '' }/>
            </div>
            <div className="data-row">
                <div className="data-row-title">
                    { state.translations ? state.translations['otv.wholesaler'] : '' }
                </div>
                <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.Wholesaler : '' }/>
            </div>
            <div className="data-row">
                <div className="data-row-title">
                    { state.translations ? state.translations['otv.occupier_score'] : '' }
                </div>
                <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.Occupier_Score : '' }/>
            </div>
        </div>
    )
}

// const MapsLink = ({ children, address, className }) => {
//     const [center, setCenter] = React.useState({});
//     const [reload, setReload] = React.useState(false)
//     const [current, setCurrent] = React.useState('')

//     const newChild = useMemo(() => {
//         const child = React.cloneElement(children, {
//             key: new Date().getTime(),
//             style: {
//                 cursor: 'pointer'
//             },
//             onClick: () => alert('open')
//         });
//         // child.style.cursor = 'pointer';
//         return child
//     }, [children])

//     const {isLoaded, loadError} = useLoadScript({
//         googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY
//     })

//     React.useEffect(() => {
//         if (isLoaded && (address !== current)) {
//             updateMap()
//             setCurrent(address)
//         }
//     }, [address, isLoaded])

//     const updateMap = () => {
//         setReload(true)
//         const geocoder = new window.google.maps.Geocoder();

//         geocoder.geocode({address: address}, (res, status) => {
//             if (status === "OK") {
//                 setCenter(res[0].geometry.location);
//                 setReload(false)
//             }
//         });
//     }

//     return (
//         !reload ? isLoaded ? newChild : children : children
//     )
// }