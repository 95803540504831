import { view } from '@risingstack/react-easy-state'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import PropertyMap from '../../../components/property-map/property-map'
import GenericPageContainer from '../../../GenericPageElements/GenericPageContainer'
import request from '../../../helpers/request'
import session from '../../../stores/session'
import { AddressBase, CMOSFormatted, CMOSLive, Summary, ValuationOffice } from './shared'

export default view(function BusinessAddressAlertSearch() {
  const [summaryData, setSummaryData] = useState({})
  let [lowerSummary, setLowerSummary] = useState(null)
  
  const [translations, setTranslations] = useState({})
  useEffect(() => {
    request(true).get('/translate?key=BAA.')
    .then(e => {
      setTranslations(e.data)
    })
    }, [])
  return (
    <GenericPageContainer
      title="Address Validation - Search"
      titleIconClass={"fak fa-id-business-icon fa-2x colour-primary"}
      titleRight={
        <div>
          <Link className="navigate" to="/portal/business-address-alert">
              To Summary
          </Link>
          { (session.user.permissions.indexOf('BUSINESS_ADDRESS_ALERTS-WORKFLOW') !== -1) ?
            <Link className="navigate" to="/portal/business-address-alert/workflow">
                To Workflow
            </Link>
          : null }
        </div>
      }
    >
      <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
        <div style={{display: 'grid', gridTemplateColumns: '2fr 1fr', gap: 10}}>
          <Summary setLowerSummary={setLowerSummary} translations={translations} summaryData={summaryData} setSummaryData={setSummaryData} />
          <PropertyMap address={summaryData['Matched_UPRN_Single_Line'] || summaryData['CMOS_Address_Single_Line']} />
        </div>

        <div style={{display: 'grid', gridAutoColumns: '1fr', gridAutoFlow: 'column', gap: 10}}>
          <CMOSLive translations={translations} summaryData={summaryData} />
          <AddressBase translations={translations} summaryData={summaryData} />
          <ValuationOffice translations={translations} summaryData={summaryData} />
          <CMOSFormatted translations={translations} summaryData={summaryData} />
        </div>
      </div>
    </GenericPageContainer>
  )
})
