import React, { Component } from 'react'
import Navigation from '../../../components/navigation/navigation'
import { ReactComponent as HouseIcon } from '../../../assets/House Icon.svg'
import Container from '../../../components/container/container'
import Footer from '../../../components/footer/footer'
import request from '../../../helpers/request'
import endpoints from '../../../helpers/endpoints'
import { view } from '@risingstack/react-easy-state'
import './voidSalesAlerts.scss';

import { Link, withRouter } from 'react-router-dom'
import session from '../../../stores/session'
import HelpVideo from '../../../components/help-video/helpVideo'
import useWSProduct from '../../../helpers/useWSProduct'

import {DeedPDFViewer} from './voidSalesAlertsSearch'
import PropertyMap from '../../../components/property-map/property-map'
import { CircularProgress } from '@mui/material'
import PageTitle from '../../../components/page-header/pageTitle'

export default view(
    withRouter(
        class VoidSalesAlertsWorkflow extends Component {
            constructor(props) {
                super(props)

                this.state = {
                    accountNumber: '',
                    customer: null,
                    message: '',
                    showMap: true,
                    purchasable: null,
                    shouldRefreshMap: false,
                    workflow: [],
                    workflowIndex: null,
                    workflowLength: 0,
                    disableActions: false,
                    translations: {},
                    loading: false
                }
            }

            componentDidMount = () => {
                this.setState({ loading: true });
                useWSProduct('void_sales_alerts');// eslint-disable-line
                request(true).get('/translate?key=vsa.').then(r => {
                    this.setState({
                        translations: r.data
                    });
                }).catch(e => {
                    console.error(e);
                })
                request(true).get(endpoints.VOID_SALES_ALERTS_WORKFLOW).then(r => {
                    this.setState({
                        workflow: r.data,
                        workflowIndex: 0,
                        workflowLength: r.data.length,
                        accountNumber: r.data[0],
                    }, () => {
                        this.getData();
                        this.setState({ loading: false });
                    });
                }).catch(e => {
                    this.setState({ loading: false });
                    console.error(e);
                });
            }

            componentWillUnmount() {
                useWSProduct('void_sales_alerts', 'unlisten');// eslint-disable-line
            }

            getData = () => {
                this.setState({ loading: true });
                request(true).get(`${endpoints.VOID_SALES_ALERTS_GET_CUSTOMER}?search=${this.state.accountNumber}`).then(r => {
                    console.log(r.data.customer);
                    this.setState({
                        customer: r.data.customer,
                        message: r.data.customer ? '' : 'Customer Does Not Exist',
                        showMap: true,
                        purchasable: r.data.customer && r.data.customer.Date_Bought === null,
                        shouldRefreshMap: true,
                    }, () => {
                        this.setState({
                            shouldRefreshMap: false,
                            disableActions: false,
                        });
                        this.setState({ loading: false });
                    });
                }).catch(e => {
                    //console.log(e);
                        this.setState({ loading: false });
                });
            }

            purchaseCustomer = () => {
                this.setState({ loading: true });
                this.setState({disableActions: true}, () => {
                    request(true).post(`${endpoints.VOID_SALES_ALERTS_BUY_CUSTOMER}`, {
                        "customer": this.state.customer.Account_Number
                    }).then(r => {
                        this.setState({ loading: false });
                        var customers = [].concat(this.state.workflow);
                        customers.splice(this.state.workflowIndex, 1);

                        this.setState({
                            workflow: customers,
                            workflowIndex: this.state.workflowIndex - 1,
                            workflowLength: customers.length,
                            customer: r.data.customer,
                            message: r.data.customer ? '' : 'Customer Does Not Exist',
                            showMap: true,
                            purchasable: r.data.customer.Date_Bought === null
                        });
                    }).catch(e => {
                        this.setState({ loading: false });
                        console.log(e);
                    })
                });
            }

            excludeCustomer = () => {
                if (!this.state.customer || !this.state.customer.Account_Number) return;
                this.setState({ loading: true });
                this.setState({disableActions: true}, () => {
                    request(true).post(endpoints.VOID_SALES_ALERTS_EXCLUDE_CUSTOMER, {
                        customer: this.state.customer.Account_Number
                    }).then(r => {
                        var customers = [].concat(this.state.workflow);
                        customers.splice(this.state.workflowIndex, 1);

                        this.setState({
                            workflow: customers,
                            workflowLength: customers.length,
                            accountNumber: customers[this.state.workflowIndex],
                        }, () => {
                            this.getData();
                            this.setState({ loading: false });
                        });
                    }).catch(e => {
                        console.error(e);
                        this.setState({ loading: false });
                    });
                });
            }

            workflowNext = () => {
                if (this.state.loading) return
                if (this.state.workflowIndex + 1 > this.state.workflow.length) return
                this.setState({
                    workflowIndex: this.state.workflowIndex + 1,
                    accountNumber: this.state.workflow[this.state.workflowIndex + 1]
                }, () => {
                    this.getData();
                });
            }

            workflowPrev = () => {
                if (this.state.loading) return
                if (this.state.workflowIndex > this.state.workflow.length) return
                this.setState({
                    workflowIndex: this.state.workflowIndex - 1,
                    accountNumber: this.state.workflow[this.state.workflowIndex - 1]
                }, () => {
                    this.getData();
                });
            }

            render() {
                return (
                    <div className="grid grid-gap-15" id="void-sales-alerts-page">
                        <Navigation/>
                        <Container>
                            <PageTitle title="Void Sale Alerts - Workflow" titleRight={
                                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    {
                                    this.state.loading ? <CircularProgress size={22} /> : null
                                    }
                                    <Link className="navigate" to="/portal/void-sales-alerts/">
                                        To Overview
                                    </Link>
                                    <Link className="navigate" to="/portal/void-sales-alerts/search">
                                        To Search
                                    </Link>
                                </div>
                            } />
                        </Container>
                        <Container>
                            <div className="ov">
                                <div className="primary-grid">
                                    <div className="left-grid">
                                        <div className="data-row with-buttons">
                                            <div className="data-row-title">
                                                Account Number
                                            </div>
                                            <div style={{ position: 'relative' }}>
                                                <input disabled={true} type="text" className="data-row-field" name="account-number" value={ this.state.accountNumber } style={{ width: 'calc(100% - 15px)', height: '100%' }}/>
                                                <span className="message">{ this.state.workflowIndex + 1 }/{ this.state.workflowLength }</span>
                                            </div>
                                            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '15px', marginLeft: '15px' }}>
                                                <button className="button background-primary colour-white" onClick={ this.workflowPrev } disabled={this.state.workflowIndex === 0}>
                                                    <i className="fas fa-angle-left"></i>
                                                </button>
                                                <button className="button background-primary colour-white" onClick={ this.workflowNext } disabled={this.state.workflowIndex === this.state.workflowLength - 1}>
                                                    <i className="fas fa-angle-right"></i>
                                                </button>
                                            </div>
                                        </div>
                                        
                                        { this.state.customer && this.state.customer.Surname !== false ?
                                            this.state.customer ? this.state.customer.Deed_Surname ? <>
                                            <div className="name-grid">
                                                <div className="data-row">
                                                    <div className="data-row-title">
                                                        { this.state.translations ? this.state.translations['vsa.title'] : '' }
                                                    </div>
                                                    <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? `${this.state.customer.Deed_Title}` : '' }/>
                                                </div>
                                                <div className="data-row">
                                                    <div className="data-row-title">
                                                        { this.state.translations ? this.state.translations['vsa.forename'] : '' }
                                                    </div>
                                                    <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? `${this.state.customer.Deed_Forename}` : '' }/>
                                                </div>
                                                <div className="data-row">
                                                    <div className="data-row-title">
                                                        { this.state.translations ? this.state.translations['vsa.surname'] : '' }
                                                    </div>
                                                    <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? `${this.state.customer.Deed_Surname}` : '' }/>
                                                </div>
                                            </div>
                                            </> : <>
                                                <div className="data-row">
                                                    <div className="data-row-title">
                                                        { this.state.translations ? this.state.translations['vsa.business'] : '' }
                                                    </div>
                                                    <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? `${this.state.customer.Deed_Business}` : '' }/>
                                                </div>
                                            </> : null
                                        :
                                            <div className="data-row">
                                                <div className="data-row-title">
                                                    { this.state.translations ? this.state.translations['vsa.Proprietor'] : '' }
                                                </div>
                                                <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? `${this.state.customer.Title}` : '' }/>
                                            </div>
                                        }
                                        <div className="data-row">
                                            <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['vsa.ClientAddress'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.SPID_Address : '' }/>
                                        </div>
                                        <div className="data-row">
                                            <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['vsa.DeedAddress'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.Deed_Address : '' }/>
                                        </div>
                                        <div className="left-grid-bottom">
                                            <div className="data-row">
                                                <div className="data-row-title">
                                                    { this.state.translations ? this.state.translations['vsa.AddressScore'] : '' }
                                                </div>
                                                <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.Address_BRAG : ''}/>
                                            </div>
                                            <div className="data-row">
                                                <div className="data-row-title">
                                                    { this.state.translations ? this.state.translations['vsa.UPRN'] : '' }
                                                </div>
                                                <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.UPRN : '' }/>
                                            </div>
                                            <div className="data-row">
                                                <div className="data-row-title">
                                                    { this.state.translations ? this.state.translations['vsa.BusinessMatch'] : '' }
                                                </div>
                                                <input type="text" className="data-row-field" disabled={true} value={this.state.customer? this.state.customer.Business_Flag : '' }/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="right-grid">
                                        { (session.user.permissions.indexOf('VSA-PURCHASE') === -1 && this.state.purchasable) ?
                                            <button className="buy-button disabled" disabled={true}>
                                                Purchase Customer
                                            </button>
                                        :
                                            <button className="buy-button" disabled={ !this.state.purchasable || this.state.disableActions } onClick={ this.purchaseCustomer }>
                                                { this.state.purchasable === true || this.state.purchasable === null ? "Purchase Customer" :
                                                    this.state.customer.Bought_By ?
                                                        `Purchased on ${this.state.customer.Date_Bought} by ${this.state.customer.Bought_By}`
                                                    :
                                                        this.state.customer.Date_Bought ?
                                                            `Purchased on ${this.state.customer.Date_Bought}`
                                                        :
                                                            'Unavailable for Purchase'
                                                }
                                            </button>
                                        }
                                        <button className="buy-button" onClick={this.excludeCustomer} disabled={ this.state.disableActions }>
                                                Exclude Customer
                                        </button>
                                        <div className="data-row">
                                            <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['vsa.VoidAgeBand'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.Voids_Age_Band : '' }/>
                                        </div>
                                        <div className="data-row">
                                            <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['vsa.TransferDate'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" disabled={true} value={this.state.customer ? this.state.customer.Transfer_Date : ''}/>
                                        </div>
                                        <div className="data-row">
                                            <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['vsa.DeedType'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.Deed_Type : '' }/>
                                        </div>
                                    </div>
                                </div>
                                <div className="secondary-grid">
                                    <PropertyMap address={ this.state.customer ? this.state.customer.SPID_Address : null } />
                                    <DeedPDFViewer customer={this.state.customer} refresh={this.getData} />
                                </div>
                            </div>
                        </Container>
                        <HelpVideo video={session.company.vca_video_url}/>
                        <Footer />
                    </div>
                )
            }
        }
    )
)