import React, { Component } from 'react'
import Navigation from '../../../components/navigation/navigation'
import { ReactComponent as HouseIcon } from '../../../assets/House Icon.svg'
import Container from '../../../components/container/container'
import Footer from '../../../components/footer/footer'
import request from '../../../helpers/request'
import endpoints from '../../../helpers/endpoints'
import { view } from '@risingstack/react-easy-state'
import './TariffValidation.scss';

import { Link, withRouter } from 'react-router-dom'
import session from '../../../stores/session'
import HelpVideo from '../../../components/help-video/helpVideo'
import useWSProduct from '../../../helpers/useWSProduct'
import PropertyMap from '../../../components/property-map/property-map'
import PageTitle from '../../../components/page-header/pageTitle'

export default view(
    withRouter(
        class TariffValidationSearch extends Component {
            constructor(props) {
                super(props)

                this.state = {
                    accountNumber: '',
                    customer: null,
                    customers: null,
                    message: '',
                    showMap: true,
                    purchasable: null,
                    shouldRefreshMap: false,
                    translations: null,
                    workflow: [],
                    workflowIndex: 0,
                    workflowLength: 0,
                }
            }

            componentDidMount = () => {
                useWSProduct('tariff_validation');// eslint-disable-line
                request(true).get('/translate?key=tv.').then(r => {
                    this.setState({
                        translations: r.data
                    });
                }).catch(e => {
                    console.error(e);
                })
            }

            accountNumberChange = (event) => {
                this.setState({
                    accountNumber: event.target.value
                });
            }

            getData = () => {
                request(true).get(`${endpoints.TARIFF_VALIDATION_GET_CUSTOMER}?search=${this.state.accountNumber}`).then(r => {
                    console.log(r.data.customer);
                    if (!r.data.customer && !r.data[0]) window.alert('Account not found.');
                    if (!Array.isArray(r.data)) r.data = [r.data];
                    this.setState({
                        workflowIndex: 0,
                    }, () => {
                        this.setState({
                            customer: r.data[0] ? r.data[0].customer : null,
                            customers: r.data,
                            workflowLength: r.data.length,
                            showMap: true,
                            purchasable: r.data[0] && r.data[this.state.workflowIndex].customer && r.data[this.state.workflowIndex].customer.Date_Bought === null && r.data[this.state.workflowIndex].customer.Occupier_Score > 0,
                            shouldRefreshMap: true,
                        }, () => {
                            this.setState({ shouldRefreshMap: false });
                        });
                    })
                }).catch(e => {
                    //console.log(e);
                });
            }

            purchaseCustomer = () => {
                request(true).post(`${endpoints.TARIFF_VALIDATION_BUY_CUSTOMER}`, {
                    "customer": this.state.customer.Account_Number,
                    "file_date": this.state.customer.File_Date,
                    "SPID_Address": this.state.customer.SPID_Address,
                    "AddressBase_Address": this.state.customer.AddressBase_Address
                }).then(r => {
                    const customers = this.state.customers
                    customers[this.state.workflowIndex].customer = r.data.customer;
                    this.setState({
                        customer: r.data.customer,
                        customers: customers,
                        message: r.data.customer ? '' : 'Account not found',
                        showMap: true,
                        purchasable: r.data.customer.Date_Bought === null
                    });
                }).catch(e => {
                    console.log(e);
                })
            }

            searchKeyDown = (e) => {
                if (e.which === 13) {
                    this.getData();
                }
            }

            componentWillUnmount() {
                useWSProduct('tariff_validation', 'unlisten');// eslint-disable-line
            }

            workflowNext = () => {
                if (this.state.workflowIndex + 1 > this.state.workflowLength) return
                this.setState({
                    workflowIndex: this.state.workflowIndex + 1,
                }, () => {
                    this.setState({
                        customer: this.state.customers[this.state.workflowIndex].customer,
                        message: this.state.customers[this.state.workflowIndex].customer ? '' : 'Account not found',
                        showMap: true,
                        purchasable: this.state.customers[this.state.workflowIndex].customer && this.state.customers[this.state.workflowIndex].customer.Date_Bought === null && this.state.customers[this.state.workflowIndex].customer.Occupier_Score > 0,
                        shouldRefreshMap: true,
                    }, () => {
                        this.setState({ shouldRefreshMap: false });
                    });
                });
            }

            workflowPrev = () => {
                if (this.state.workflowIndex > this.state.workflowLength) return
                this.setState({
                    workflowIndex: this.state.workflowIndex - 1,
                }, () => {
                    this.setState({
                        customer: this.state.customers[this.state.workflowIndex].customer,
                        message: this.state.customers[this.state.workflowIndex].customer ? '' : 'Account not found',
                        showMap: true,
                        purchasable: this.state.customers[this.state.workflowIndex].customer && this.state.customers[this.state.workflowIndex].customer.Date_Bought === null && this.state.customers[this.state.workflowIndex].customer.Occupier_Score > 0,
                        shouldRefreshMap: true,
                    }, () => {
                        this.setState({ shouldRefreshMap: false });
                    });
                });
            }

            render() {
                return (
                    <div className="grid grid-gap-15" id="tariff-validation-page">
                        <Navigation/>
                        <Container>
                            <PageTitle title="Tariff Validation - Search" titleRight={
                                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <Link className="navigate" to='/portal/tariff-validation/'>
                                        Overview
                                    </Link>
                                    { (session.user.permissions.indexOf('TARIFF_VALIDATION-WORKFLOW') !== -1) ?
                                        <Link className="navigate" to='/portal/tariff-validation/workflow' >
                                            Workflow
                                        </Link>
                                    : null }
                                    <Link className="navigate" to="/portal/tariff-validation/report">
                                        Report
                                    </Link>
                                </div>
                            } />
                        </Container>
                        <Container>
                            <div className="vcv">
                                <div className="primary-grid">
                                    <div className="left-grid">
                                        <div className="data-row with-buttons">
                                            <div className="data-row-title">
                                            { this.state.translations ? this.state.translations['tv.account_number'] : '' }
                                            </div>
                                            <div style={{ position: 'relative' }}>
                                                <input type="text" className="data-row-field" name="account-number" value={ this.state.accountNumber } style={{ width: 'calc(100% - 15px)', height: '100%' }} onChange={ this.accountNumberChange } onKeyPress={ this.searchKeyDown }/>
                                                <span className="message">{ this.state.workflowIndex + 1 }/{ this.state.workflowLength }</span>
                                            </div>
                                            <span className="error-message">{ this.state.message }</span>
                                            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '15px', marginLeft: '15px' }}>
                                                <button className="button background-primary colour-white" onClick={ this.workflowPrev } disabled={this.state.workflowIndex === 0}>
                                                    <i className="fas fa-angle-left"></i>
                                                </button>
                                                <button className="button background-primary colour-white" onClick={ this.workflowNext } disabled={this.state.workflowIndex === this.state.workflowLength - 1}>
                                                    <i className="fas fa-angle-right"></i>
                                                </button>
                                            </div>
                                        </div>
                                        { this.state.customer && this.state.customer.Surname !== false ?
                                            <div className="name-grid">
                                                <div className="data-row">
                                                    <div className="data-row-title">
                                                        Title
                                                    </div>
                                                    <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.Title ? `${this.state.customer.Title}` : '' : '' }/>
                                                </div>
                                                <div className="data-row">
                                                    <div className="data-row-title">
                                                        Forename
                                                    </div>
                                                    <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? `${this.state.customer.Forename}` : '' }/>
                                                </div>
                                                <div className="data-row">
                                                    <div className="data-row-title">
                                                        Surname
                                                    </div>
                                                    <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? `${this.state.customer.Surname}` : '' }/>
                                                </div>
                                            </div>
                                        :
                                            <div className="data-row">
                                                <div className="data-row-title">
                                                    { this.state.translations ? this.state.translations['tv.name'] : '' }
                                                </div>
                                                <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? `${this.state.customer.Title}` : '' }/>
                                            </div>
                                        }
                                        <div className="left-grid-bottom">
                                            <div className="data-row">
                                                <div className="data-row-title">
                                                    { this.state.translations ? this.state.translations['tv.date_of_birth'] : '' }
                                                </div>
                                                <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.Date_of_Birth : ''}/>
                                            </div>
                                            <div className="data-row">
                                                <div className="data-row-title">
                                                    { this.state.translations ? this.state.translations['tv.landline'] : '' }
                                                </div>
                                                <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.Landline : '' }/>
                                            </div>
                                            <div className="data-row">
                                                <div className="data-row-title">
                                                    { this.state.translations ? this.state.translations['tv.mobile'] : '' }
                                                </div>
                                                <input type="text" className="data-row-field" disabled={true} value={this.state.customer ? this.state.customer.Mobile : '' }/>
                                            </div>
                                        </div>
                                        <div className="data-row">
                                            <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['tv.client_address'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.SPID_Address : '' }/>
                                        </div>
                                        <div className="data-row">
                                            <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['tv.matched_address'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.AddressBase_Address : '' }/>
                                        </div>
                                        <div className="left-grid-bottom">
                                            <div className="data-row">
                                                <div className="data-row-title">
                                                    { this.state.translations ? this.state.translations['tv.address_score'] : '' }
                                                </div>
                                                <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.Address_Quality : ''}/>
                                            </div>
                                            <div className="data-row">
                                                <div className="data-row-title">
                                                    { this.state.translations ? this.state.translations['tv.uprn'] : '' }
                                                </div>
                                                <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.UPRN : '' }/>
                                            </div>
                                            <div className="data-row">
                                                <div className="data-row-title">
                                                    { this.state.translations ? this.state.translations['tv.new_connection'] : '' }
                                                </div>
                                                <input type="text" className="data-row-field" disabled={true} value={this.state.customer? this.state.customer.New_Connection : '' }/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="right-grid">
                                        <button className="search-button" onClick={ this.getData }>
                                            Search
                                        </button>
                                        { (session.user.permissions.indexOf('TARIFF_VALIDATION-PURCHASE') === -1 && this.state.purchasable) ?
                                            <button className="buy-button disabled" disabled={true}>
                                                Purchase Customer
                                            </button>
                                        :
                                            <button className="buy-button" disabled={ !this.state.purchasable} onClick={ this.purchaseCustomer }>
                                                { this.state.purchasable === true || this.state.purchasable === null ? "Purchase Customer" :
                                                    this.state.customer.Bought_By ?
                                                        `Purchased on ${this.state.customer.Date_Bought} by ${this.state.customer.Bought_By}`
                                                    :
                                                        this.state.customer.Date_Bought ?
                                                            `Purchased on ${this.state.customer.Date_Bought}`
                                                        :
                                                            'Unavailable for Purchase'
                                                }
                                            </button>
                                        }
                                        <div className="data-row">
                                            <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['tv.email'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" disabled={true} value={this.state.customer ? this.state.customer.Email : '' }/>
                                        </div>
                                        <div className="data-row">
                                            <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['tv.void_age_band'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.Voids_Age_Band : '' }/>
                                        </div>
                                        <div className="data-row">
                                            <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['tv.account_type'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" disabled={true} value={this.state.customer ? this.state.customer.Type : ''}/>
                                        </div>
                                        <div className="data-row">
                                            <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['tv.consumption'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.Consumption : '' }/>
                                        </div>
                                    </div>
                                </div>
                                <div className="secondary-grid">
                                    <PropertyMap address={ this.state.customer ? this.state.customer.SPID_Address : null } />
                                    <table className="table borders">
                                        <thead>
                                            <tr>
                                                <th>
                                                    Occupier Footprint
                                                </th>
                                                <th>
                                                    Evidence
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    Listed on electoral roll
                                                </td>
                                                <td>
                                                    { this.state.customer ? this.state.customer.Electoral_Roll : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Listed on BT register
                                                </td>
                                                <td>
                                                    { this.state.customer ? this.state.customer.BT_Line : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Count of secured loans
                                                </td>
                                                <td>
                                                    { this.state.customer ? this.state.customer.Secured_Loans : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Named on Title Deed
                                                </td>
                                                <td>
                                                    { this.state.customer ? this.state.customer.Named_On_Title_Deed : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    GAS redirect in place
                                                </td>
                                                <td>
                                                { this.state.customer ? this.state.customer.GAS_Redirect : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    NCoA redirect in place
                                                </td>
                                                <td>
                                                    { this.state.customer ? this.state.customer.NCOA_Redirect : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Count of bank accounts
                                                </td>
                                                <td>
                                                    { this.state.customer ? this.state.customer.Bank_Accounts : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Count of unsecured loans
                                                </td>
                                                <td>
                                                    { this.state.customer ? this.state.customer.Unsecured_Loans : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Count of utility accounts
                                                </td>
                                                <td>
                                                    { this.state.customer ? this.state.customer.Utility_Accounts : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Count of telco/media accounts
                                                </td>
                                                <td>
                                                    { this.state.customer ? this.state.customer.Telco_Accounts : '' }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table className="table borders">
                                        <thead>
                                            <tr>
                                                <th>
                                                    Occupier Footprint
                                                </th>
                                                <th>
                                                    Evidence
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    Count of home credit accounts
                                                </td>
                                                <td>
                                                    { this.state.customer ? this.state.customer.Home_Credit_Accounts : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Count of mail order accounts
                                                </td>
                                                <td>
                                                    { this.state.customer ? this.state.customer.Mail_Order : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Count of revolving facilities
                                                </td>
                                                <td>
                                                    { this.state.customer ? this.state.customer.Revolving_Facilities : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Count of other facilities
                                                </td>
                                                <td>
                                                    { this.state.customer ? this.state.customer.Other_Accounts : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Count of recent credit searches
                                                </td>
                                                <td>
                                                    { this.state.customer ? this.state.customer.Credit_Searches : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Data on Demand: Loans
                                                </td>
                                                <td>
                                                    { this.state.customer ? this.state.customer.DoD_Loans : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Data on Demand: Subscriptions
                                                </td>
                                                <td>
                                                    { this.state.customer ? this.state.customer.DoD_Subscriptions : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Latest date linked to property
                                                </td>
                                                <td>
                                                    { this.state.customer ? this.state.customer.Latest_Date : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Earliest date linked to property
                                                </td>
                                                <td>
                                                    { this.state.customer ? (this.state.customer.Earliest_Date !== null ? this.state.customer.Earliest_Date : '-')  : '' }
                                                </td>
                                            </tr>
                                            {/* <tr>
                                                <td>
                                                    Latest date linked to property
                                                </td>
                                                <td>
                                                    { this.state.customer ? this.state.customer.Latest_Date : '' }
                                                </td>
                                            </tr> */}
                                            {/* <tr>
                                                <td>
                                                    Date of most recent update
                                                </td>
                                                <td>
                                                    { this.state.customer ? this.state.customer.Recent_Date : '' }
                                                </td>
                                            </tr> */}
                                            <tr>
                                                <td>
                                                    Occupier Score
                                                </td>
                                                <td>
                                                    { this.state.customer ? this.state.customer.Occupier_Score + " Stars" : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Occupier Score Change
                                                </td>
                                                <td>
                                                    { this.state.customer ? this.state.customer.Occupier_Change : '' }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Container>
                        <HelpVideo video={session.company.vca_video_url}/>
                        <Footer />
                    </div>
                )
            }
        }
    )
)

