import React, { useEffect, useState } from 'react'
import Page from '../../../components/page/page'
import PropertyMap from '../../../components/property-map/property-map';
import request from '../../../helpers/request';
import { DataRow } from '../CompaniesHouseAlerts/sharedComponents';
import { CotValidationTitle } from './cotValidation'
import { CotValidationAccountDetails, CotValidationClientDetails, CotValidationMatchedDetails, CotValidationMatchStatus, CotValidationOccupierFootprint, SearchRow } from './cotValidationShared'
import './cotValidation.scss';
import endpoints from '../../../helpers/endpoints';
import GenericDataRow from "../../../GenericPageElements/GenericDataRow";
import ProductDataFilter from '../../../components/product-data-filter/product-data-filter'
import session from '../../../stores/session'
import BulkBuy from '../../../components/cot-alert-bulk-buy/bulkBuy';

export default function CotValidationSearch() {
const [date, setDate] = useState(null);
const [dates, setDates] = useState([]);
const [filterState, setFilterState] = useState({});
const [filterArrays, setFilterArrays] = useState({});
const [bulkModel, setBulkModel] = useState('');
const [filter, setFilter] = useState({key: '', value: ''});

  const [translations, setTranslations] = useState([]);
  const [data, setData] = useState(null)
  const [modalMode, setModalMode] = useState(null)

  useEffect(() => {
    request(true).get('/translate?key=cotval.').then(r => {
      setTranslations(r.data)
    }).catch(e => {
        console.error(e);
    })
  }, []);

  useEffect(() => {
    if (!date) return
    request(true).get(endpoints.COT_VALIDATION_SUMMARY_WATERFALL + `?date=${date}`, {
        params: {
            filter: JSON.stringify(filterState)
        }
    }).then(e => {
        if (e.status === 200) {
            setData(e.data);
        }
    })
  }, [date, filterState]);

  const addFilter = (field, value) => {
    setFilterState({
      ...filterState,
      [field]: value
    })
  }

  const filterTables = (field, value) => {
    setFilter({key: field, value: value})
  } 

  useEffect(() => {
    if(filter.key == "" || filter.value == "") return
    if (!filterArrays[filter.key]) { filterArrays[filter.key] = {} }
    setFilterArrays({
        ...filterArrays,
        [filter.key]: {
            ...filterArrays[filter.key],
            [filter.value]: !filterArrays[filter.key][filter.value]
        }
    })
  }, [filter])

  useEffect(() => {
    if(filter.key == "" || filter.value == "") return

    let newFilter = [];

    Object.keys(filterArrays[filter.key]).forEach(key => {
        if (filterArrays[filter.key][key]) {
            newFilter.push(key);
        }
    });

    setFilterState({
        ...filterState,
        [filter.key]: newFilter
    });
  }, [filterArrays])

  const getDates = () => {
    request(true)
      .get(endpoints.COT_VALIDATION_SUMMARY_WATERFALL_DATES)
      .then((r) => {
        if (r.status === 200) {
          setDates(r.data.dates);
          setDate(r.data.dates[0].raw);
        }
      })
      .catch((e) => {
        //console.log(e);
      });
  };

  useEffect(() => {
    getDates();
  }, []);

  return (
    <Page title={'Cleanse & Validation - Summary'} titleRight={<CotValidationTitle showS3={false} showReportLink={false} />}>
    <div style={{maxWidth: '20vw', marginBottom: 15}}>
        <GenericDataRow title={"File Date"} contentColour={"white"}>
            <select
                onChange={(_) => setDate(_.target.value)}
                value={date}
                style={{
                width: "100%",
                height: "100%",
                border: "none",
                backgroundColor: "white",
                paddingLeft: "10px",
                }}
            >
                {dates.map((item) => {
                return (
                    <option key={item.raw} value={item.raw}>
                    {item.pretty}
                    </option>
                );
                })}
            </select>
        </GenericDataRow>
      </div>
      <div className="CotValidation" style={{gridTemplateColumns: "2fr 1fr"}}>
        <div className='CotValidation-Summary'>
            <div className='CotValidation-Summary-Tables'>
                <svg style={{position: "absolute", transform: "rotate(180deg)", top: "14.5%", right: window.innerWidth > 1930 ? "54.8%" : window.innerWidth > 1500 ? "53.7%" : "53.15%", height: "11%"}}>
                    <defs><marker id="arrow" markerUnits="userSpaceOnUse" markerWidth="13" markerHeight="13" refX="2" refY="6" orient="auto"><path d="M0,0 L0,13 L13,6 L0,0" style={{fill: "grey"}}></path></marker></defs>
                    <path d="M100,100 L100,14" style={{stroke: "grey", strokeWidth: "5px", fill: "none", markerEnd: "url(\"#arrow\")"}}></path></svg>
                <table>
                    <tbody>
                        <tr>
                            <th style={{ fontWeight: "normal" }}>
                                <div>Issued: </div>
                                <div>{data ? data.Issued ? data.Issued.toLocaleString() : 0 : 0}</div>
                            </th>
                            <th style={ filterState['Address_Band'] && filterState['Address_Band'].includes('Valid') ? { fontWeight: "bold"} : {fontWeight: "normal"}} onClick={ () => filterTables('Address_Band', 'Valid')} >
                                <div>Valid Address: </div>
                                <div style={ filterState['Address_Band'] && filterState['Address_Band'].includes('Valid') ? { background: '#445468', color: 'white' } : null }>{data ? data.Valid_Address ? parseInt(data.Valid_Address).toLocaleString() : 0 : 0}</div>
                            </th>
                            <th style={ filterState['Address_Band'] && filterState['Address_Band'].includes('Invalid_Address') ? { fontWeight: "bold"} : {fontWeight: "normal"}} onClick={ () => filterTables('Address_Band', 'Invalid_Address')} >
                                <div>Invalid Address: </div>
                                <div style={ filterState['Address_Band'] && filterState['Address_Band'].includes('Invalid_Address') ? { background: '#445468', color: 'white' } : null }>{data ? data.Invalid_Address ? parseInt(data.Invalid_Address).toLocaleString() : 0 : 0}</div>
                            </th>
                            <th style={ filterState['Address_Band'] && filterState['Address_Band'].includes('Invalid_Occupiers') ? { fontWeight: "bold"} : {fontWeight: "normal"}} onClick={ () => filterTables('Address_Band', 'Invalid_Occupiers')} >
                                <div>Invalid Occupiers: </div>
                                <div style={ filterState['Address_Band'] && filterState['Address_Band'].includes('Invalid_Occupiers') ? { background: '#445468', color: 'white' } : null }>{data ? data.Invalid_Occupiers ? parseInt(data.Invalid_Occupiers).toLocaleString() : 0 : 0}</div>
                            </th>
                        </tr>
                    </tbody>
                </table>
                <svg style={{position: "absolute", transform: "rotate(180deg)", top: "39.5%", right: window.innerWidth > 1930 ? "30.75%" : window.innerWidth > 1500 ? "29.8%" : "29.3%", height: "11%"}}>
                    <defs><marker id="arrow" markerUnits="userSpaceOnUse" markerWidth="13" markerHeight="13" refX="2" refY="6" orient="auto"><path d="M0,0 L0,13 L13,6 L0,0" style={{fill: "grey"}}></path></marker></defs>
                    <path d="M100,100 L100,14" style={{stroke: "grey", strokeWidth: "5px", fill: "none", markerEnd: "url(\"#arrow\")"}}></path></svg>
                <svg style={{position: "absolute", transform: "rotate(180deg)", top: "39.5%", right: window.innerWidth > 1930 ? "58.4%" : window.innerWidth > 1500 ? "57.75%" : "57.55%", height: "35.6%"}}>
                    <defs><marker id="arrow" markerUnits="userSpaceOnUse" markerWidth="13" markerHeight="13" refX="2" refY="6" orient="auto"><path d="M0,0 L0,13 L13,6 L0,0" style={{fill: "grey"}}></path></marker></defs>
                    <path d="M50,200 L50,11" style={{stroke: "grey", strokeWidth: "5px", fill: "none", markerEnd: "url(\"#arrow\")"}}></path></svg>
                <table>
                    <tbody>
                        <tr>
                            <th style={ filterState['Occupier_Band'] && filterState['Occupier_Band'].includes('Confirmed') ? { fontWeight: "bold"} : {fontWeight: "normal"}} onClick={ () => filterTables('Occupier_Band', 'Confirmed')} >
                                <div>Confirmed Occupiers: </div>
                                <div style={ filterState['Occupier_Band'] && filterState['Occupier_Band'].includes('Confirmed') ? { background: '#445468', color: 'white' } : null }>{data ? data.Confirmed_Occupiers ? parseInt(data.Confirmed_Occupiers).toLocaleString() : 0 : 0}</div>
                            </th>
                            <th style={ filterState['Occupier_Band'] && filterState['Occupier_Band'].includes('Non_resident_Occupiers') ? { fontWeight: "bold"} : {fontWeight: "normal"}} onClick={ () => filterTables('Occupier_Band', 'Non_resident_Occupiers')} >
                                <div>Non-resident Occupiers: </div>
                                <div style={ filterState['Occupier_Band'] && filterState['Occupier_Band'].includes('Non_resident_Occupiers') ? { background: '#445468', color: 'white' } : null }>{data ? data.Non_resident_Occupiers ? parseInt(data.Non_resident_Occupiers).toLocaleString() : 0 : 0}</div>
                            </th>
                            <th style={ filterState['Occupier_Band'] && filterState['Occupier_Band'].includes('Unvalidated_Occupiers') ? { fontWeight: "bold"} : {fontWeight: "normal"}} onClick={ () => filterTables('Occupier_Band', 'Unvalidated_Occupiers')} >
                                <div>Unvalidated Occupiers: </div>
                                <div style={ filterState['Occupier_Band'] && filterState['Occupier_Band'].includes('Unvalidated_Occupiers') ? { background: '#445468', color: 'white' } : null }>{data ? data.Unvalidated_Occupiers ? parseInt(data.Unvalidated_Occupiers).toLocaleString() : 0 : 0}</div>
                            </th>
                        </tr>
                    </tbody>
                </table>
                <table>
                    <tbody>
                        <tr>
                            <th style={ filterState['Residency_Status'] && filterState['Residency_Status'].includes('Gone_Away') ? { fontWeight: "bold"} : {fontWeight: "normal"}} onClick={ () => filterTables('Residency_Status', 'Gone_Away')} >
                                <div>Goneaway: </div>
                                <div style={ filterState['Residency_Status'] && filterState['Residency_Status'].includes('Gone_Away') ? { background: '#445468', color: 'white' } : null }>{data ? data.Goneaway ? parseInt(data.Goneaway).toLocaleString() : 0 : 0}</div>
                            </th>
                            <th style={ filterState['Residency_Status'] && filterState['Residency_Status'].includes('Deceased') ? { fontWeight: "bold"} : {fontWeight: "normal"}} onClick={ () => filterTables('Residency_Status', 'Deceased')} >
                                <div>Deceased: </div>
                                <div style={ filterState['Residency_Status'] && filterState['Residency_Status'].includes('Deceased') ? { background: '#445468', color: 'white' } : null }>{data ? data.Deceased ? parseInt(data.Deceased).toLocaleString() : 0 : 0}</div>
                            </th>
                        </tr>
                    </tbody>
                </table>
                <svg style={{position: "absolute", transform: "rotate(180deg)", top: "89.5%", right: window.innerWidth > 1930 ? "30.75%" : window.innerWidth > 1500 ? "29.8%" : "29.3%", width: "100vw", height: "13%"}}>
                    <defs><marker id="arrow" markerUnits="userSpaceOnUse" markerWidth="13" markerHeight="13" refX="2" refY="6" orient="auto"><path d="M0,0 L0,13 L13,6 L0,0" style={{fill: "grey"}}></path></marker></defs>
                    <path d={window.innerWidth > 1930 ? "M100,150 L100,29 L690, 29 L690,10" : window.innerWidth > 1500 ? "M100,150 L100,29 L590, 29 L590,10" : "M100,150 L100,29 L540, 29 L540,10" } style={{stroke: "grey", strokeWidth: "5px", fill: "none", markerEnd: "url(\"#arrow\")", width: "100vw"}}></path></svg>
                <svg style={{position: "absolute", transform: "rotate(180deg)", top: "89.5%", right: window.innerWidth > 1930 ? "6.62%" : window.innerWidth > 1500 ? "5.9%" : "5.4%", width: "50%", height: "13%"}}>
                    <defs><marker id="arrow" markerUnits="userSpaceOnUse" markerWidth="13" markerHeight="13" refX="2" refY="6" orient="auto"><path d="M0,0 L0,13 L13,6 L0,0" style={{fill: "grey"}}></path></marker></defs>
                    <path d="M100,150 L100,29 L640,29" style={{stroke: "grey", strokeWidth: "5px", fill: "none"}}></path></svg>
                <table>
                    <tbody>
                        <tr>
                            <th style={ filterState['Residency_Status'] && filterState['Residency_Status'].includes('Validated') ? { fontWeight: "bold"} : {fontWeight: "normal"}} onClick={ () => filterTables('Residency_Status', 'Validated')} >
                                <div>Validated: </div>
                                <div style={ filterState['Residency_Status'] && filterState['Residency_Status'].includes('Validated') ? { background: '#445468', color: 'white' } : null }>{data ? data.Validated ? parseInt(data.Validated).toLocaleString() : 0 : 0}</div>
                            </th>
                            <th style={ filterState['Residency_Status'] && filterState['Residency_Status'].includes('Name_Cleanse') ? { fontWeight: "bold"} : {fontWeight: "normal"}} onClick={ () => filterTables('Residency_Status', 'Name_Cleanse')} >
                                <div>Name Cleanse: </div>
                                <div style={ filterState['Residency_Status'] && filterState['Residency_Status'].includes('Name_Cleanse') ? { background: '#445468', color: 'white' } : null }>{data ? data.Name_Cleanse ? parseInt(data.Name_Cleanse).toLocaleString() : 0 : 0}</div>
                            </th>
                            <th style={ filterState['Residency_Status'] && filterState['Residency_Status'].includes('DOB_Cleanse') ? { fontWeight: "bold"} : {fontWeight: "normal"}} onClick={ () => filterTables('Residency_Status', 'DOB_Cleanse')} >
                                <div>DOB Cleanse: </div>
                                <div style={ filterState['Residency_Status'] && filterState['Residency_Status'].includes('DOB_Cleanse') ? { background: '#445468', color: 'white' } : null }>{data ? data.DOB_Cleanse ? parseInt(data.DOB_Cleanse).toLocaleString() : 0 : 0}</div>
                            </th>
                        </tr>
                    </tbody>
                </table>
            </div>
            <table className='DOB-table'>
                <thead>
                    <tr>
                        <th></th>
                        <th>Forename Cleanse</th>
                        <th>Forename Append</th>
                        <th>Surname Cleanse</th>
                        <th>Name Matched</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>DOB Match</td>
                        <td style={ filterState['Name_DOB_Details'] && filterState['Name_DOB_Details'].includes('DOB_Match_Forename_Cleanse') ? { fontWeight: "bold", background: '#445468', color: 'white'} : {fontWeight: "normal"}} onClick={ () => filterTables('Name_DOB_Details', 'DOB_Match_Forename_Cleanse') }>{ data ? data.DOB_Match_Forename_Cleanse ? parseInt(data.DOB_Match_Forename_Cleanse).toLocaleString() : 0 : 0 }</td>
                        <td style={ filterState['Name_DOB_Details'] && filterState['Name_DOB_Details'].includes('DOB_Match_Forename_Append') ? { fontWeight: "bold", background: '#445468', color: 'white'} : {fontWeight: "normal"}} onClick={ () => filterTables('Name_DOB_Details', 'DOB_Match_Forename_Append') }>{ data ? data.DOB_Match_Forename_Append ? parseInt(data.DOB_Match_Forename_Append).toLocaleString() : 0 : 0 }</td>
                        <td style={ filterState['Name_DOB_Details'] && filterState['Name_DOB_Details'].includes('DOB_Match_Surname_Cleanse') ? { fontWeight: "bold", background: '#445468', color: 'white'} : {fontWeight: "normal"}} onClick={ () => filterTables('Name_DOB_Details', 'DOB_Match_Surname_Cleanse') }>{ data ? data.DOB_Match_Surname_Cleanse ? parseInt(data.DOB_Match_Surname_Cleanse).toLocaleString() : 0 : 0 }</td>
                        <td style={ filterState['Name_DOB_Details'] && filterState['Name_DOB_Details'].includes('DOB_Match_Name_Matched') ? { fontWeight: "bold", background: '#445468', color: 'white'} : {fontWeight: "normal"}} onClick={ () => filterTables('Name_DOB_Details', 'DOB_Match_Name_Matched') }>{ data ? data.DOB_Match_Name_Matched ? parseInt(data.DOB_Match_Name_Matched).toLocaleString() : 0 : 0 }</td>
                    </tr>
                    <tr>
                        <td>DOB Cleanse</td>
                        <td style={ filterState['Name_DOB_Details'] && filterState['Name_DOB_Details'].includes('DOB_Cleanse_Forename_Cleanse') ? { fontWeight: "bold", background: '#445468', color: 'white'} : {fontWeight: "normal"}} onClick={ () => filterTables('Name_DOB_Details', 'DOB_Cleanse_Forename_Cleanse') }>{ data ? data.DOB_Cleanse_Forename_Cleanse ? parseInt(data.DOB_Cleanse_Forename_Cleanse).toLocaleString() : 0 : 0 }</td>
                        <td style={ filterState['Name_DOB_Details'] && filterState['Name_DOB_Details'].includes('DOB_Cleanse_Forename_Append') ? { fontWeight: "bold", background: '#445468', color: 'white'} : {fontWeight: "normal"}} onClick={ () => filterTables('Name_DOB_Details', 'DOB_Cleanse_Forename_Append') }>{ data ? data.DOB_Cleanse_Forename_Append ? parseInt(data.DOB_Cleanse_Forename_Append).toLocaleString() : 0 : 0 }</td>
                        <td style={ filterState['Name_DOB_Details'] && filterState['Name_DOB_Details'].includes('DOB_Cleanse_Surname_Cleanse') ? { fontWeight: "bold", background: '#445468', color: 'white'} : {fontWeight: "normal"}} onClick={ () => filterTables('Name_DOB_Details', 'DOB_Cleanse_Surname_Cleanse') }>{ data ? data.DOB_Cleanse_Surname_Cleanse ? parseInt(data.DOB_Cleanse_Surname_Cleanse).toLocaleString() : 0 : 0 }</td>
                        <td style={ filterState['Name_DOB_Details'] && filterState['Name_DOB_Details'].includes('DOB_Cleanse_Name_Matched') ? { fontWeight: "bold", background: '#445468', color: 'white'} : {fontWeight: "normal"}} onClick={ () => filterTables('Name_DOB_Details', 'DOB_Cleanse_Name_Matched') }>{ data ? data.DOB_Cleanse_Name_Matched ? parseInt(data.DOB_Cleanse_Name_Matched).toLocaleString() : 0 : 0 }</td>
                    </tr>
                    <tr>
                        <td>DOB Append</td>
                        <td style={ filterState['Name_DOB_Details'] && filterState['Name_DOB_Details'].includes('DOB_Append_Forename_Cleanse') ? { fontWeight: "bold", background: '#445468', color: 'white'} : {fontWeight: "normal"}} onClick={ () => filterTables('Name_DOB_Details', 'DOB_Append_Forename_Cleanse') }>{ data ? data.DOB_Append_Forename_Cleanse ? parseInt(data.DOB_Append_Forename_Cleanse).toLocaleString() : 0 : 0 }</td>
                        <td style={ filterState['Name_DOB_Details'] && filterState['Name_DOB_Details'].includes('DOB_Append_Forename_Append') ? { fontWeight: "bold", background: '#445468', color: 'white'} : {fontWeight: "normal"}} onClick={ () => filterTables('Name_DOB_Details', 'DOB_Append_Forename_Append') }>{ data ? data.DOB_Append_Forename_Append ? parseInt(data.DOB_Append_Forename_Append).toLocaleString() : 0 : 0 }</td>
                        <td style={ filterState['Name_DOB_Details'] && filterState['Name_DOB_Details'].includes('DOB_Append_Surname_Cleanse') ? { fontWeight: "bold", background: '#445468', color: 'white'} : {fontWeight: "normal"}} onClick={ () => filterTables('Name_DOB_Details', 'DOB_Append_Surname_Cleanse') }>{ data ? data.DOB_Append_Surname_Cleanse ? parseInt(data.DOB_Append_Surname_Cleanse).toLocaleString() : 0 : 0 }</td>
                        <td style={ filterState['Name_DOB_Details'] && filterState['Name_DOB_Details'].includes('DOB_Append_Name_Matched') ? { fontWeight: "bold", background: '#445468', color: 'white'} : {fontWeight: "normal"}} onClick={ () => filterTables('Name_DOB_Details', 'DOB_Append_Name_Matched') }>{ data ? data.DOB_Append_Name_Matched ? parseInt(data.DOB_Append_Name_Matched).toLocaleString() : 0 : 0 }</td>
                    </tr>
                </tbody>
            </table>
          </div>
          <div className='right'>
            <div className="filters">
                <div className="filter-title">
                    <div className="title">Filters</div>
                    <div className="clear" onClick={ () => setFilterState({}) }>Clear All</div>
                </div>
                <ProductDataFilter dataFilterEndpoint={endpoints.COT_VALIDATION_DATA_FILTERS} filterState={filterState} applyFilter={addFilter} />
            </div>
            <div className='grid grid-columns-1 grid-gap-5 mta' style={{marginTop: "10%"}}>
              { (session.user.permissions.indexOf('COT_VALIDATION-BULK-BUY') !== -1) ?
                  <button className="button bulk-buy-button compact smaller-text" onClick={() => setBulkModel('purchase')}>
                      Bulk Buy
                  </button>
              : '' }
            </div>
          </div>
      </div>
      <BulkBuy bulkEndpoint={endpoints.COT_VALIDATION_SUMMARY_BULK} modalIsOpen={bulkModel === 'purchase'} closeModal={() => setBulkModel('')} filters={filterState} date={date} />,
    </Page>
  )
}
