import React, { Component } from 'react'
import Navigation from '../../../components/navigation/navigation'
import { ReactComponent as HouseIcon } from '../../../assets/House Icon.svg'
import Container from '../../../components/container/container'
import Footer from '../../../components/footer/footer'
import request from '../../../helpers/request'
import endpoints from '../../../helpers/endpoints'
import { view } from '@risingstack/react-easy-state'
import './proprietorValidation.scss';

import { Link, withRouter } from 'react-router-dom'
import session from '../../../stores/session'
import HelpVideo from '../../../components/help-video/helpVideo'
import useWSProduct from '../../../helpers/useWSProduct'

import {DeedPDFViewer} from './proprietorValidationSearch'
import PropertyMap from '../../../components/property-map/property-map'
import { CircularProgress } from '@mui/material'
import PageTitle from '../../../components/page-header/pageTitle'
import ProductIcons from '../../../helpers/productIcons'

export default view(
    withRouter(
        class ProprietorValidationWorkflow extends Component {
            constructor(props) {
                super(props)

                this.state = {
                    accountNumber: '',
                    customer: null,
                    message: '',
                    showMap: true,
                    purchasable: null,
                    shouldRefreshMap: false,
                    workflow: [],
                    workflowIndex: null,
                    workflowLength: 0,
                    disableActions: false,
                    translations: {},
                    loading: false,
                    selectedDeed: null,
                    customers: [{}, {}, {}], 
                    purchasedCustomers: []
                }
            }

            componentDidMount = () => {
                this.setState({ loading: true });
                useWSProduct('proprietor_validation');// eslint-disable-line
                request(true).get('/translate?key=proprietor-validation.').then(r => {
                    this.setState({
                        translations: r.data
                    });
                }).catch(e => {
                    console.error(e);
                })
                request(true).get(endpoints.PROPRIETOR_VALIDATION_WORKFLOW).then(r => {
                    this.setState({
                        workflow: r.data,
                        workflowIndex: 0,
                        workflowLength: r.data.length,
                        accountNumber: r.data[0],
                    }, () => {
                        this.getData();
                        this.setState({ loading: false });
                    });
                }).catch(e => {
                    this.setState({ loading: false });
                    console.error(e);
                });
            }

            componentWillUnmount() {
                useWSProduct('proprietor_validation', 'unlisten');// eslint-disable-line
            }

            getData = () => {
                this.setState({ loading: true });
                request(true).get(`${endpoints.PROPRIETOR_VALIDATION_GET_CUSTOMER}?search=${this.state.accountNumber}`).then(r => {
                    console.log(r.data.customer);
                    if (!r.data.customers.length) window.alert('No Results Found.');
                    if (!Array.isArray(r.data.customers)) r.data.customers = [r.data.customers];
                    while (r.data.customers.length < 3) { r.data.customers.push({})}
                    this.setState({
                        customers: r.data.customers,
                    })
                    const purchasedCustomer = r.data.customers.find(customer => customer.Date_Bought != null)
                    purchasedCustomer ? 
                        this.setState({
                            customer: purchasedCustomer,
                            showMap: true,
                            shouldRefreshMap: true,
                        }, () => {
                            this.setState({ loading: false });
                            this.setState({ shouldRefreshMap: false });
                        })
                    :
                        this.setState({
                            customer: r.data.customers[0],
                            showMap: true,
                            shouldRefreshMap: true,
                        }, () => {
                            this.setState({ loading: false });
                            this.setState({ shouldRefreshMap: false });
                        });
                }).catch(e => {
                    //console.log(e);
                        this.setState({ loading: false });
                });
            }

            purchaseCustomer = (Deed_TitleNo) => {
                this.setState({ loading: true });
                this.setState({disableActions: true}, () => {
                    request(true).post(`${endpoints.PROPRIETOR_VALIDATION_BUY_CUSTOMER}`, {
                        "customer": this.state.customer.Account_Number,
                        "Deed_TitleNo": Deed_TitleNo
                    }).then(r => {
                        this.setState({ loading: false });
                        var customers = [].concat(this.state.workflow);
                        customers.splice(this.state.workflowIndex, 1);

                        this.setState({
                            workflow: customers,
                            workflowIndex: this.state.workflowIndex - 1,
                            workflowLength: customers.length,
                            customer: r.data.customer,
                            message: r.data.customer ? '' : 'Customer Does Not Exist',
                            showMap: true,
                            purchasable: r.data.customer.Date_Bought === null,
                            selectedDeed: r.data.customer.Deed_TitleNo
                        });
                    }).catch(e => {
                        this.setState({ loading: false });
                        console.log(e);
                    })
                });
            }

            excludeCustomer = () => {
                if (!this.state.customer || !this.state.customer.Account_Number) return;
                this.setState({ loading: true });
                this.setState({disableActions: true}, () => {
                    request(true).post(endpoints.PROPRIETOR_VALIDATION_EXCLUDE_CUSTOMER, {
                        customer: this.state.customer.Account_Number
                    }).then(r => {
                        var customers = [].concat(this.state.workflow);
                        customers.splice(this.state.workflowIndex, 1);

                        this.setState({
                            workflow: customers,
                            workflowLength: customers.length,
                            accountNumber: customers[this.state.workflowIndex],
                        }, () => {
                            this.getData();
                            this.setState({ loading: false });
                        });
                    }).catch(e => {
                        console.error(e);
                        this.setState({ loading: false });
                    });
                });
            }

            workflowNext = () => {
                if (this.state.loading) return
                if (this.state.workflowIndex + 1 > this.state.workflow.length) return
                this.setState({
                    workflowIndex: this.state.workflowIndex + 1,
                    accountNumber: this.state.workflow[this.state.workflowIndex + 1]
                }, () => {
                    this.getData();
                });
            }

            workflowPrev = () => {
                if (this.state.loading) return
                if (this.state.workflowIndex > this.state.workflow.length) return
                this.setState({
                    workflowIndex: this.state.workflowIndex - 1,
                    accountNumber: this.state.workflow[this.state.workflowIndex - 1]
                }, () => {
                    this.getData();
                });
            }

            render() {
                return (
                    <div className="grid grid-gap-15" id="proprietor-validation-page">
                        <Navigation/>
                        <Container>
                            <PageTitle title="Proprietor Validation - Workflow" iconClass={ProductIcons.BusinessIcon.props.className} titleRight={
                                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    {
                                    this.state.loading ? <CircularProgress size={22} /> : null
                                    }
                                    <Link className="navigate" to="/portal/proprietor-validation/">
                                        To Overview
                                    </Link>
                                    <Link className="navigate" to="/portal/proprietor-validation/search">
                                        To Search
                                    </Link>
                                </div>
                            } />
                        </Container>
                        <Container>
                            <div className="proprietor-validation">
                                <div className="primary-grid">
                                    <div className="left-grid">
                                        <div className="data-row with-buttons">
                                            <div className="data-row-title">
                                            { this.state.translations ? this.state.translations['proprietor-validation.AccountNumber'] : '' }
                                            </div>
                                            <div style={{ position: 'relative' }}>
                                                <input disabled={true} type="text" className="data-row-field" name="account-number" value={ this.state.accountNumber } style={{ width: 'calc(100% - 15px)', height: '100%' }}/>
                                                <span className="message">{ this.state.workflowIndex + 1 }/{ this.state.workflowLength }</span>
                                            </div>
                                            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '15px', marginLeft: '15px' }}>
                                                <button className="button background-primary colour-white" onClick={ this.workflowPrev } disabled={this.state.workflowIndex === 0}>
                                                    <i className="fas fa-angle-left"></i>
                                                </button>
                                                <button className="button background-primary colour-white" onClick={ this.workflowNext } disabled={this.state.workflowIndex === this.state.workflowLength - 1}>
                                                    <i className="fas fa-angle-right"></i>
                                                </button>
                                            </div>
                                        </div>
                                        
                                        { this.state.customer && this.state.customer.Surname !== false ?
                                            this.state.customer ? this.state.customer.Deed_Surname ? <>
                                            <div className="name-grid">
                                                <div className="data-row">
                                                    <div className="data-row-title">
                                                    Title
                                                    </div>
                                                    <input type="text" className="data-row-field" disabled={true} value={ this.state.customer && this.state.customer.Deed_Title ? `${this.state.customer.Deed_Title}` : '' }/>
                                                </div>
                                                <div className="data-row">
                                                    <div className="data-row-title">
                                                    Forename
                                                    </div>
                                                    <input type="text" className="data-row-field" disabled={true} value={ this.state.customer && this.state.customer.Deed_Forename ? `${this.state.customer.Deed_Forename}` : '' }/>
                                                </div>
                                                <div className="data-row">
                                                    <div className="data-row-title">
                                                    Surname
                                                    </div>
                                                    <input type="text" className="data-row-field" disabled={true} value={ this.state.customer && this.state.customer.Deed_Surname ? `${this.state.customer.Deed_Surname}` : '' }/>
                                                </div>
                                            </div>
                                            </> : <>
                                                <div className="data-row">
                                                    <div className="data-row-title">
                                                        { this.state.translations ? this.state.translations['proprietor-validation.CompanyName'] : '' }
                                                    </div>
                                                    <input type="text" className="data-row-field" disabled={true} value={ this.state.customer && this.state.customer.Company_Name ? `${this.state.customer.Company_Name}` : '' }/>
                                                </div>
                                            </> : null
                                        :
                                            <div className="data-row">
                                                <div className="data-row-title">
                                                    { this.state.translations ? this.state.translations['proprietor-validation.CompanyName'] : '' }
                                                </div>
                                                <input type="text" className="data-row-field" disabled={true} value={ this.state.customer && this.state.customer.Company_Name ? `${this.state.customer.Company_Name}` : '' }/>
                                            </div>
                                        }
                                        <div className="data-row">
                                            <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['proprietor-validation.ClientAddress'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" disabled={true} value={ this.state.customer && this.state.customer.SPID_Address ? this.state.customer.SPID_Address : '' }/>
                                        </div>
                                        <div className="data-row">
                                            <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['proprietor-validation.MatchedAddress'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" disabled={true} value={ this.state.customer && this.state.customer.AddressBase_Address ? this.state.customer.AddressBase_Address : '' }/>
                                        </div>
                                        <div className="left-grid-bottom">
                                            <div className="data-row">
                                                <div className="data-row-title">
                                                    { this.state.translations ? this.state.translations['proprietor-validation.UPRN'] : '' }
                                                </div>
                                                <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.UPRN : ''}/>
                                            </div>
                                            <div className="data-row">
                                                <div className="data-row-title">
                                                    { this.state.translations ? this.state.translations['proprietor-validation.VOA'] : '' }
                                                </div>
                                                <input type="text" className="data-row-field" disabled={true} value={ this.state.customer && this.state.customer.AD_VOA_No ? this.state.customer.AD_VOA_No : '' }/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="right-grid">
                                        <button className="buy-button" onClick={this.excludeCustomer} disabled={ this.state.disableActions }>
                                                Exclude Customer
                                        </button>
                                        <div className="data-row">
                                            <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['proprietor-validation.AddressStatus'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" disabled={true} value={this.state.customer ? this.state.customer.Address_Score : '' }/>
                                        </div>
                                        <div className="data-row">
                                            <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['proprietor-validation.DeedCount'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" disabled={true} value={ this.state.customer && this.state.customer.deed_count ? this.state.customer.deed_count : '' }/>
                                        </div>
                                        <div className="data-row">
                                            <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['proprietor-validation.DeedsFound'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" disabled={true} value={this.state.customer ? this.state.customer.Deeds_Found : ''}/>
                                        </div>
                                        <div className="data-row">
                                            <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['proprietor-validation.OnCCOD'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" disabled={true} value={ this.state.customer && this.state.customer.On_CCOD ? this.state.customer.On_CCOD : '' }/>
                                        </div>
                                    </div>
                                </div>
                                <div className="secondary-grid">
                                    <div style={{display: 'flex', flexDirection: 'row', gap: '.5em', height: '100%'}}>
                                        <table className={'table borders squish smaller-text'} style={{ height: '100%'}}>
                                            <thead>
                                                <tr>
                                                    <th style={{width: "150px"}}></th>
                                                    { this.state.customers.map(customer => {
                                                        return (
                                                            <th style={{width: '27%'}}>
                                                                { (session.user.permissions.indexOf('PROPRIETOR_VALIDATION-PURCHASE') != -1 && customer.Deed_Status == "Deed Available") ?
                                                                        <button style={{display: 'inline-block', marginRight: '10px'}} className="buy-button" disabled={customer.Date_Bought ? true : false} onClick={() => this.purchaseCustomer(customer.Deed_TitleNo)}>
                                                                            {
                                                                                customer.Date_Bought ?
                                                                                    `Purchased on ${customer.Date_Bought}` + customer.Bought_By ? ` by ${customer.Bought_By}` : null
                                                                                :
                                                                                    'Buy'
                                                                            }
                                                                        </button>
                                                                    :
                                                                        <button style={{display: 'inline-block', marginRight: '10px'}} className="buy-button disabled" disabled>
                                                                            Buy
                                                                        </button>
                                                                }
                                                                { (customer.Date_Bought) ? 
                                                                    <button style={{display: 'inline-block'}} className="buy-button" onClick={ () => { this.setState({selectedDeed: customer.Deed_TitleNo})}}>View</button>
                                                                :
                                                                    <button style={{display: 'inline-block'}} className="buy-button disabled" disabled={true}>View</button>
                                                                }
                                                            </th>
                                                        )
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Tenure</td>
                                                    { this.state.customers.map(customer => {
                                                        return <td>{ customer.Deed_Type}</td>
                                                    })}
                                                </tr>
                                                <tr>
                                                    <td>Title Number</td>
                                                    { this.state.customers.map(customer => {
                                                        return <td>{ customer.Deed_TitleNo}</td>
                                                    })}
                                                </tr>
                                                <tr>
                                                    <td>Registered Occupier</td>
                                                    { this.state.customers.map(customer => {
                                                        return <td>{ customer.Registered_Occupier}</td>
                                                    })}
                                                </tr>
                                                <tr>
                                                    <td>Search Address</td>
                                                    { this.state.customers.map(customer => {
                                                        return <td>{ customer.Search_Address}</td>
                                                    })}
                                                </tr>
                                                <tr>
                                                    <td>Matched Address</td>
                                                    { this.state.customers.map(customer => {
                                                        return <td>{ customer.Deed_Address}</td>
                                                    })}
                                                </tr>
                                                <tr>
                                                    <td>Address Match</td>
                                                    { this.state.customers.map(customer => {
                                                        return <td>{ customer.Address_Match}</td>
                                                    })}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <DeedPDFViewer customer={this.state.customer} refresh={this.getData} />
                                </div>
                            </div>
                        </Container>
                        <HelpVideo video={session.company.vca_video_url}/>
                        <Footer />
                    </div>
                )
            }
        }
    )
)