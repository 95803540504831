import React from 'react';
import Modal from 'react-modal'
import Dropzone from '../../components/dropzone/dropzone'
import Filesaver from 'file-saver'
import request from '../../helpers/request'
import { useEffect } from 'react';
import Axios from 'axios';

const timeSince = (date) => {
    var seconds = Math.floor((new Date() - date) / 1000)

    var interval = Math.floor(seconds / 31536000)

    if (interval > 1) {
        return interval + ' years'
    }
    interval = Math.floor(seconds / 2592000)
    if (interval > 1) {
        return interval + ' months'
    }
    interval = Math.floor(seconds / 86400)
    if (interval > 1) {
        return interval + ' days'
    }
    interval = Math.floor(seconds / 3600)
    if (interval > 1) {
        return interval + ' hours'
    }
    interval = Math.floor(seconds / 60)
    if (interval > 1) {
        return interval + ' minutes'
    }
    return Math.floor(seconds) + ' seconds'
}

export default function S3Modal ({ showModal, closeModal, modalMode, listingUrl, uploadUrl, ...props })
{
    const [files, setFiles] = React.useState([]);
    const [modalMessage, setModalMessage] = React.useState('');

    const downloadFile = (url, name) => {
        Filesaver.saveAs(url, name)
    }

    const truncateString = (str, num) => {
        if (str.length <= num) {
            return str
        }

        return str.slice(0, num) + '...'
    }

    const onUpload = (acceptedFiles) => {
        if (acceptedFiles.length !== 1) {
            setModalMessage('You may only upload one file at a time.');
            return
        }

        acceptedFiles.forEach((file) => {
            request(true).post(uploadUrl, { name: file.name }).then(r => {
                console.log(r.data.url);
                setModalMessage(`Uploading ${truncateString(file.name, 12)}... Please Wait...`)
                Axios.put(r.data.url, file, {
                    headers: {
                        'Content-Type': file.type
                    }
                }).then(r => {
                    setModalMessage(`Successfully uploaded ${truncateString(file.name, 12)}...`);
                }).catch(e => {
                    setModalMessage('An error occurred whilst uploading this file to S3.');
                })
            }).catch(e => {
                setModalMessage('An error occurred whilst retrieving a signed S3 URL');
            });
        })
    }

    const getFile = (url, name) => {
        request(true).get(url).then(r => {
            downloadFile(r.data.download, name);
        }).catch(e => {
            console.log(e);
        });
    }

    useEffect(() => {
        if (modalMode === 'download') {
          request(true)
            .get(listingUrl)
            .then((r) => {
                setFiles(r.data.files);
                setModalMessage(" ");
            })
            .catch((r) => {
                setFiles([]);
                setModalMessage('An error occurred whilst retrieving the file listing!');
            })
        }
    }, [modalMode, listingUrl]);

    return (
        <Modal
            style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}
            className="card"
            isOpen={showModal}
            onRequestClose={closeModal}
            contentLabel={modalMode === 'download' ? 'Download Return File' : 'Issue File'}
            appElement={document.getElementById('root')}
        >
            <h2>
                {modalMode === 'download' ? 'Download Return File' : 'Issue File'}
            </h2>
            {modalMode === 'download' ? (
                <div className="download">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Last Modified</th>
                                <th>Size (KB)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {files.map((file) => {
                                return (
                                    <tr key={file.name}>
                                        <td>
                                            <p style={{ color: 'blue', cursor: 'pointer' }} onClick={() => getFile(file.download, file.name)}>
                                                {truncateString(file.name, 25)}
                                            </p>
                                        </td>
                                        <td>{ timeSince(new Date(file.last_modified * 1000)) } ago</td>
                                        <td>{(file.size / 1000).toFixed(1).toLocaleString()}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    { modalMessage ?
                        <p className="modal-message">{modalMessage}</p>
                    :
                        <i style={{ marginTop: '10px' }} className='fas fa-loader fa-fw fa-spin fa-2x'></i>
                    }
                </div>
            ) : (
                    <div className="upload">
                        <Dropzone onDrop={onUpload} />
                        <p className="modal-message">{modalMessage}</p>
                    </div>
                )}
        </Modal>
    )
}
