import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import GenericPageContainer from '../../../GenericPageElements/GenericPageContainer'
import request from '../../../helpers/request';
import { GenericTable } from '../VacantCOTAlerts/sharedComponents';
import endpoints from '../../../helpers/endpoints'
import ProductChartInfo from '../../../components/product-chart-info/product-info-chart';

const GapSitesAlertsOutwardTable = ({ selectedOutward, setSelectedOutward, exportToExcel, translations }) => {
  const [outwards, setOutwards] = useState([]);
  const [sortBy, setSortBy] = useState({ column: "Outward", order: "dsc" });
  const headers = [
    ["Outward", translations ? translations['gsa.outward'] : ''], 
    ["Client", translations ? translations['gsa.client'] : ''], 
    ["ABP", translations ? translations['gsa.abp'] : ''], 
    ["VOA", translations ? translations['gsa.voa'] : ''], 
    ["Matched", translations ? translations['gsa.matched'] : ''], 
    ["commercial", translations ? translations['gsa.commercial'] : ''], 
    ["Bulk Meter", translations ? translations['gsa.bulk_meter'] : ''], 
    ["Flat Above Shop", translations ? translations['gsa.flat_above_shop'] : ''], 
    ["Split", translations ? translations['gsa.split'] : ''], 
    ["New Connection", translations ? translations['gsa.new_connection'] : ''],
    ["Domestic GAP Site", translations ? translations['gsa.domestic_gap_site'] : ''],
    ["Business GAP Site", translations ? translations['gsa.business_gap_site'] : ''],
   ]

   const rawHeaders = headers.map(header =>header[1]);

  const rows = outwards ? outwards.map(outward => [
    outward.Outward ? outward.Outward.replace(' ', String.fromCharCode(160)) : null,
    outward.Client,
    outward.ABP,
    outward.VOA,
    outward.Matched,
    outward.Commercial,
    outward["Bulk Meter"],
    outward["Flat Above Shop"],
    outward.Split,
    outward["New Connection"],
    outward["Domestic GAP Site"],
    outward["Business GAP Site"]
  ]) : null;

  useEffect(() => {
    request(true).get(endpoints.GAP_SITES_ALERTS_OUTWARDS).then(e => {
      setOutwards(e.data);
    });
  }, []);
 
  const sortTable = (column) => {
    if (sortBy.column == column) {
      setSortBy({ ...sortBy, order: sortBy.order == "asc" ? "dsc" : "asc" });
    } else {
      setSortBy({ column, order: "asc" })
    }
  }

  useEffect(() => {
    const sorted = [...outwards].sort((a, b) => {
      if (sortBy.column) {
        const valueA = parseInt(a[sortBy.column]);
        const valueB = parseInt(b[sortBy.column]);

        if (valueA < valueB) {
          return sortBy.order == "asc" ? -1 : 1;
        } else if (valueA > valueB) {
          return sortBy.order == "asc" ? 1 : -1;
        }
      }
      return 0;
    });

    setOutwards(sorted);
  }, [sortBy]);

  return rows ? (
    <div style={{overflowX: 'auto', maxHeight: '300px'}}>
      <table className='table smaller-text squish'>
        <thead style={{position: 'sticky', top: '0px'}}>
          <tr>
            {
              headers.map(header => {
                return (
                  <th onClick={() => sortTable(header[0])}>{header[1] ? header[1] : ''}</th>
                )
              })
            }
            <th><img src='/Excel-logo.svg' style={{cursor: 'pointer', height: '24px', width: '24px', position: 'relative', right: '17.5%'}} onClick={ () => exportToExcel(rawHeaders, rows, "GapSitesAlertsOutwardsTable")}></img></th>
          </tr>
        </thead>
        <tbody style={{whiteSpace: 'nowrap'}}>
          {
            rows ?rows.map((row, index) => {
              return (
                <tr>
                  {
                    row.map(data => {
                      return <td>{data}</td>
                    })
                  }
                  <td>
                    <div onClick={() => setSelectedOutward(outwards[index].Outward)} style={{cursor: 'pointer'}}>
                      {
                        selectedOutward === outwards[index].Outward ? <i className='fas fa-circle'></i> : <i className='far fa-circle'></i>
                      }
                    </div>
                  </td>
                </tr>
              )
            }) : null
          }
        </tbody>
      </table>
    </div>
  ) : null;
}

const GapSitesAlertsPostcodeTable = ({ selectedOutward, setSelectedPostcode, selectedPostcode, exportToExcel, translations }) => {
  const [postcodes, setPostcodes] = useState([]);
  const [sortBy, setSortBy] = useState({ column: "Postcode", order: "dsc" });
  const headers = [
    ["Postcode", translations ? translations['gsa.postcode'] : ''], 
    ["Client", translations ? translations['gsa.client'] : ''], 
    ["ABP", translations ? translations['gsa.abp'] : ''], 
    ["VOA", translations ? translations['gsa.voa'] : ''], 
    ["Matched", translations ? translations['gsa.matched'] : ''], 
    ["commercial", translations ? translations['gsa.commercial'] : ''], 
    ["Bulk Meter", translations ? translations['gsa.bulk_meter'] : ''], 
    ["Flat Above Shop", translations ? translations['gsa.flat_above_shop'] : ''], 
    ["Split", translations ? translations['gsa.split'] : ''], 
    ["New Connection", translations ? translations['gsa.new_connection'] : ''],
    ["Domestic GAP Site", translations ? translations['gsa.domestic_gap_site'] : ''],
    ["Business GAP Site", translations ? translations['gsa.business_gap_site'] : ''],
  ]

   const rawHeaders = headers.map(header =>header[1]);

  const rows = postcodes ? postcodes.map(postcode => [
    postcode.Postcode ? postcode.Postcode.replace(' ', String.fromCharCode(160)) : null,
    postcode.Client,
    postcode.ABP,
    postcode.VOA,
    postcode.Matched,
    postcode.Commercial,
    postcode["Bulk Meter"],
    postcode["Flat Above Shop"],
    postcode.Split,
    postcode["New Connection"],
    postcode["Domestic GAP Site"],
    postcode["Business GAP Site"]
  ]) : null;

  useEffect(() => {
    // Get Postcodes for Outward
    request(true).get(endpoints.GAP_SITES_ALERTS_POSTCODES, {
      params: {
        outward: selectedOutward
      }
    }).then(e => {
      setPostcodes(e.data);
    })
  }, [selectedOutward]);

  const sortTable = (column) => {
    if (sortBy.column == column) {
      setSortBy({ ...sortBy, order: sortBy.order == "asc" ? "dsc" : "asc" });
    } else {
      setSortBy({ column, order: "asc" })
    }
  }

  useEffect(() => {
    const sorted = [...postcodes].sort((a, b) => {
      if (sortBy.column) {
        const valueA = parseInt(a[sortBy.column]);
        const valueB = parseInt(b[sortBy.column]);

        if (valueA < valueB) {
          return sortBy.order == "asc" ? -1 : 1;
        } else if (valueA > valueB) {
          return sortBy.order == "asc" ? 1 : -1;
        }
      }
      return 0;
    });
    setPostcodes(sorted);
  }, [sortBy]);

  return (
    <div style={{overflowX: 'auto', maxHeight: '230px'}}>
      <table className='table smaller-text squish'>
        <thead style={{position: 'sticky', top: '0px'}}>
          <tr>
            {
              headers.map(header => {
                return (
                  <th onClick={() => sortTable(header[0])}>{header[1] ? header[1] : ''}</th>
                )
              })
            }
            <th><img src='/Excel-logo.svg' style={{cursor: 'pointer', height: '24px', width: '24px', position: 'relative', right: '17.5%'}} onClick={ () => exportToExcel(rawHeaders, rows, "GapSitesAlertsPostcodesTable")}></img></th>
          </tr>
        </thead>
        <tbody style={{whiteSpace: 'nowrap'}}>
          {
            rows ? rows.map((row, index) => {
              return (
                <tr>
                  {
                    row.map(data => {
                      return <td>{data}</td>
                    })
                  }
                  <td>
                    <div onClick={() => setSelectedPostcode(postcodes[index].Postcode)} style={{cursor: 'pointer'}}>
                      {
                        selectedPostcode === postcodes[index].Postcode ? <i className='fas fa-circle'></i> : <i className='far fa-circle'></i>
                      }
                    </div>
                  </td>
                </tr>
              )
            }) : null
          }
        </tbody>
      </table>
    </div> 
  )
}

const GapSitesAlertsHouseMap = ({ selectedPostcode, setSelectedUPRN, selectedOutward }) => {
  // Map Functionality
  const {isLoaded, loadError} = useLoadScript({
      googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY
  });
  const [reload, setReload] = useState(true);

  // Data Extraction
  const [centerPos, setCenterPos] = useState(null);
  const [markers, setMarkers] = useState([])

  // Get Markers for Postcode
  useEffect(() => {
    request(true).get(endpoints.GAP_SITES_ALERTS_MAP_DATA, {
      params: {
        postcode: selectedPostcode
      }
    }).then(e => {
      setMarkers(e.data.data);
      setCenterPos(e.data.meta.center);
      setReload(true);
    });
  }, [selectedPostcode]);

  useEffect(() => {
    setReload(false);
  }, [selectedOutward])

  const onClick = useCallback((marker) => {
    console.log(marker); 
    setSelectedUPRN(marker.ABP_UPRN)
  }, [setSelectedUPRN])

  return isLoaded && !loadError && reload && markers.length ? <>
      <GoogleMap
        center={{ lat: Number(centerPos.latitude), lng: Number(centerPos.longitude) }} 
        zoom={18} 
        mapContainerStyle={{height: '100%', width: '100%', maxHeight: '100%'}} 
        >
        {
          markers.map(marker => {
            var icon = "";
            switch (marker.Status) {
              case 'Matched':
                icon = '/circle-solid.png'
                break;
              case 'Bulk Meter Site':
                icon = '/circle-solid-blue.png';
                break;
              case 'Private Supply':
                icon = '/circle-solid-pink.png';
                break;
              case 'New Connection':
                icon = '/circle-solid-yellow.png';
                break;
              case 'Domestic GAP Site':
                icon = '/circle-gap-domestic-solid.png';
                break;
              case 'Business GAP Site':
                icon = '/circle-gap-business-solid.png';
                break;
              default:
                icon = '/circle-xmark-solid.png';
                break;
            }
            return (<Marker
              key={marker.ABP_URPN + `_${Math.random()}`}
                position={{ lat: Number(marker.Latitude), lng: Number(marker.Longitude) }} 
                onClick={() => onClick(marker) }
                icon={icon}
              />
              )
          })
        }
      </GoogleMap>
    </> : <div style={{backgroundColor: 'lightgray', height: '100%'}}></div>
}

const GapSitesAlertsVOAEvidence = ({ uprnEvidence: data }) => {
    const rows = [
      ['ABP Address', 'ABP_Address'],
      ['ABP UPRN', 'ABP_UPRN'],
      ['ABP Update Date', 'ABP_UPDATE_DATE'],
      ['VOA CT Address', 'VOA_CT_Address'],
      ['VOA CT Ref', 'VOA_CT_Ref'],
      ['VOA CT Effective Date', 'VOA_CT_Effective_Date'],
      ['VOA BR Address', 'VOA_BR_Address'],
      ['VOA BR Ref', 'VOA_BR_Ref'],
      ['VOA BR Effective Date', 'VOA_BR_Effective_Date'],
      ['Land Registry Address', 'Land_Registry_Address'],
      ['Land Registry Deed', 'Land_Registry_Deed'],
      ['Land Registry Last Sold Date', ''],
    ];
    return <GenericTable data={data} extraClass={'smaller-text squish left'} rows={rows} title={'Evidence'} subtitle='Detail' firstColWidth={200} />
}

const GapSitesAlertsOccupierEvidenceLeft = ({ uprnEvidence: data }) => {
  const rows = [
    ['Listed on electoral roll', 'Electoral_Roll'],
    ['Listed on BT register', 'BT_Line'],
    ['Count of secured loans', 'Secured_Loans'],
    ['GAS redirect in place', 'GAS_Redirect'],
    ['NCoA redirect in place', 'NCOA_Redirect'],
    ['Count of bank accounts', 'Bank_Accounts'],
    ['Count of unsecured loans', 'Unsecured_Loans'],
    ['Count of utility accounts', 'Utility_Accounts'],
  ]
  return <GenericTable data={data} extraClass={'smaller-text squish left'} rows={rows} title={'Occupier Footprint'} subtitle='Evidence' />
}

const GapSitesAlertsOccupierEvidenceRight = ({ uprnEvidence: data }) => {
  const rows = [
    ['Count of telco/media accounts', 'Telco_Accounts'],
    ['Count of home credit accounts', 'Home_Credit_Accounts'],
    ['Count of mail order accounts', 'Mail_Order'],
    ['Count of revolving facilities', 'Revolving_Facilities'],
    ['Count of other facilities', 'Other_Accounts'],
    ['Count of recent credit searches', 'Credit_Searches'],
    ['Earliest date linked to property', 'Earliest_Date'],
    ['Occupier Score', 'Occupier_Score'],
  ]
  return <GenericTable data={data} extraClass={'smaller-text squish left'} rows={rows} title={'Occupier Footprint'} subtitle='Evidence' />
}

const GapSitesAlertsTitleBar = ({ exportToExcel, translations }) => {
  const [totals, setTotals] = useState(null);
  // const headers = totals ? Object.keys(totals) : null;
  const rows = totals ? Object.values(totals) : null;

  const headers = [
    translations ? translations['gsa.client'] : '', 
    translations ? translations['gsa.abp'] : '', 
    translations ? translations['gsa.voa'] : '', 
    translations ? translations['gsa.matched'] : '', 
    translations ? translations['gsa.commercial'] : '', 
    translations ? translations['gsa.bulk_meter'] : '', 
    translations ? translations['gsa.flat_above_shop'] : '', 
    translations ? translations['gsa.split'] : '', 
    translations ? translations['gsa.new_connection'] : '',
    translations ? translations['gsa.gap'] : '',
    translations ? translations['gsa.total'] : '',
    translations ? translations['gsa.gaps_closed'] : '',
    translations ? translations['gsa.gap_pounds'] : '',
    translations ? translations['gsa.potential_leakage'] : '',
   ]

  useEffect(() => {
    request(true).get(endpoints.GAP_SITES_ALERTS_TOTALS).then(e => {
      setTotals(e.data);
    });
  }, []);

  return (
    <div>
      <table className='table smaller-text squish' style={{whiteSpace: 'nowrap'}}>
        <thead>
          <tr>
            {
              totals ?
                headers.map((header, index) => {
                  if (headers.length == index + 1) {
                    return(
                      <th>{header}<img src='/Excel-logo.svg' style={{cursor: 'pointer', height: '24px', width: '24px', position: 'relative', left: '9%', top: '6px'}} onClick={ () => exportToExcel(headers, [rows], "GapSitesAlertsTotalsTable")}></img></th>
                    )
                  }
                  return (
                    <th>{header}</th>
                  )
                })
              :
                <th></th>
            }
          </tr>
        </thead>
        <tbody>
          {
            <tr>
              {
                totals ? 
                  rows.map(total => {
                    return (
                      <td>{total}</td>
                    )
                  })
                :
                  <td></td>
              }
            </tr>
          }
        </tbody>
      </table>
    </div>
  )
}

const GapSitesAlertsAddressEvidenceTable = ({ uprnEvidence: data, setUprnEvidence, selectedUPRN, exportToExcel, selectedPostcode, translations }) => {
  const [selectedOptions, setSelectedOptions] = useState({});
  const [sortBy, setSortBy] = useState({ value: "Domestic GAP Site", order: "dsc" });
  const headers = [
    ["Client Ref", translations ? translations['gsa.client_ref'] : ''], 
    ["Client Address", translations ? translations['gsa.client_address'] : ''], 
    ["ABP Address", translations ? translations['gsa.abp_address'] : ''], 
    ["ABP UPRN", translations ? translations['gsa.abp_uprn'] : ''], 
    ["VOA Type", translations ? translations['gsa.voa_type'] : ''], 
    ["VOA Address", translations ? translations['gsa.voa_address'] : ''], 
    ["Confidence", translations ? translations['gsa.confidence'] : ''], 
    ["Status", translations ? translations['gsa.status'] : ''], 
    ["State", translations ? translations['gsa.state'] : ''], 
  ]

  const rawHeaders = headers.map(header =>header[1]);

  const rows = data ? data.map(property => 
    [
      property.Client_Reference,
      property.Client_Address,
      property.ABP_Address,
      property.ABP_UPRN,
      property.VOA_Type,
      property.VOA_Address,
      property.Occupier_Score,
      property.Status
    ]) : null;

  const updateStatus = (event, uprn, index) => {

    setUprnEvidence((uprnEvidence) =>{
      const evidence = [...uprnEvidence];
      evidence[index].state = event.target.value;
      return evidence;
    })

    request(true).post(endpoints.GAP_SITES_ALERTS_UPDATE_STATUS, {
      state: event.target.value,
      uprn: uprn
    }).catch(e => {
      console.log(e);
    });
  }

  const sortTable = () => {
    if(!sortBy.value || sortBy.value === "Matched") {
      setSortBy({...sortBy, value: "Domestic GAP Site"});
    } else if (sortBy.value === "Domestic GAP Site") {
      setSortBy({...sortBy, value: "Business GAP Site"});
    } else if (sortBy.value === "Business GAP Site") {
      setSortBy({...sortBy, value: "Matched"})
    }
  }

  useEffect(() => {
    if(!data) {
      return;
    }
    const sorted = [...data].sort((a, b) => {
      if (sortBy.value) {
        if (a.Status === sortBy.value && b.Status !== sortBy.value) return -1;
        if (a.Status !== sortBy.value && b.Status === sortBy.value) return 1;
      }
      return a.Status.localeCompare(b.Status);
    });

    setUprnEvidence(sorted);
  }, [sortBy]);

  return rows ? (
    <div style={{overflowX: 'auto', maxHeight: "626px", width: '90vw'}}>
      <table className='table smaller-text suish' style={{width: 'fit-content', minWidth: '100%', tableLayout: 'auto'}}>
        <thead style={{position: 'sticky', top: '0'}}>
          <tr>
            {
              headers.map((header, index) => {
                if (headers.length == index + 1) {
                  return(
                    <th>{header[1]}<img src='/Excel-logo.svg' style={{cursor: 'pointer', height: '24px', width: '24px', position: 'absolute', right: '0.75%', top: '25%'}} onClick={ () => exportToExcel(rawHeaders, rows, "GapSitesAlertsAddressEvidenceTable")}></img></th>
                  )
                }
                return(
                  header[0] === "Status" ? 
                    <th onClick={() => sortTable()}>{header[1]}</th>
                  :
                  <th>{header[1]}</th>
                )
              })
            }
          </tr>
        </thead>
        <tbody style={{whiteSpace: 'nowrap'}}>
          {
            rows ? rows.map((row, index) => {
              return (
                <tr style={{fontWeight: data[index].ABP_UPRN == selectedUPRN ? 'bold' : 'normal' }}>
                  {
                    row.map(data => {
                      return <td style={{textAlign: 'left'}}>{data}</td>
                    })
                  }
                  <td>
                    <select style={{height: 28, border: '1px solid var(--primary)', borderRadius: 5, width: 130, textAlign: 'center'}} 
                      onChange={(event) => updateStatus(event, data[index].ABP_UPRN, index)} 
                      value={selectedOptions[index] ? selectedOptions[index] : data[index].Status == "Domestic GAP Site" || data[index].Status == "Business GAP Site" ? data[index].state ?? "New" : "Not available"} 
                      >
                      {data[index].Status !== "Matched" ? (
                        <>
                          <option value="New">New</option> 
                          <option value="In progress">In progress</option>
                          <option value="GAP Accepted">Gap Accepted</option>
                          <option value="GAP Rejected">Gap Rejected</option>
                        </>
                      ) : (
                        <option value="Not available"> Not available</option>
                      )}
                    </select>
                  </td>
                </tr>
              )
            }) : null
          }
        </tbody>
      </table>
    </div>
  ) : null;
}

export default function GapSitesAlerts() {
  const [translations, setTranslations] = useState(null);
  const [selectedOutward, setSelectedOutward] = useState(null);
  const [selectedPostcode, setSelectedPostcode] = useState(null);
  const [postcodeAddresses, setPostcodeAddresses] = useState(null);

  const [selectedUPRN, setSelectedUPRN] = useState(null);
  const [uprnEvidence, setUprnEvidence] = useState(null);

  useEffect(() => {
    request(true).get('/translate?key=gsa.').then(r => {
      setTranslations(r.data);
  }).catch(e => {
      console.error(e);
  })
  }, []);

  useEffect(() => {
    console.log('Postcode Change', selectedPostcode)
    if (selectedPostcode) {
      request(true).get(endpoints.GAP_SITES_ALERTS_DATA, {
        params: {
          postcode: selectedPostcode
        }
      }).then(e => {
        const sorted = [...e.data].sort((a, b) => {
          if (a.Status === "Domestic GAP Site" && b.Status !== "Domestic GAP Site") return -1;
          if (a.Status !== "Domestic GAP Site" && b.Status === "Domestic GAP Site") return 1;
          return a.Status.localeCompare(b.Status);
        });
    
        setUprnEvidence(sorted);
      });
    }
  }, [selectedPostcode]);

  useEffect(() => {
    setUprnEvidence(null);
    setSelectedPostcode(null);
    setSelectedUPRN(null);
  }, [selectedOutward])

  const exportToExcel = (headers, rows, filename) => {
    const csv = [
      headers.map(header => {
        if (typeof header === 'string') {
          header = `"${header}"`;
          header = header.replace(String.fromCharCode(163), '£');
        }
        return header;
      }).join(','), 
      ...rows.map(row => 
        row.map((value) => {
          if (typeof value === 'string') {
            value = `"${value}"`;
            value = value.replace(String.fromCharCode(160), ' ');
          }
          return value;
        }).join(','))].join('\n');

  
    const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, `${filename}.csv`);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', `${filename}.csv`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  return <GenericPageContainer title={'GAP Sites Alerts'} containerWidth='90vw'>
    <div style={{ marginBottom: '15px' }}>
      <ProductChartInfo path={window.location.pathname} parentStyle={{display: 'flex', justifyContent:'flex-end'}} style={{margin: '0px', cursor: 'pointer', color: 'var(--primary)', paddingLeft: '15px'}} />
      <GapSitesAlertsTitleBar exportToExcel={exportToExcel} translations={translations} />
    </div>
    <div style={{display: 'grid', gridTemplateColumns: '3fr 2fr', gap: 15, marginBottom: '15px', minHeight: "718px"}}>
      <div style={{display: 'flex', flexDirection: 'column', gap: 15}}>
        <GapSitesAlertsOutwardTable setSelectedOutward={setSelectedOutward} selectedOutward={selectedOutward} exportToExcel={exportToExcel} translations={translations} />
        {
          selectedOutward ? <>
            <GapSitesAlertsPostcodeTable selectedOutward={selectedOutward} selectedPostcode={selectedPostcode} setSelectedPostcode={setSelectedPostcode} exportToExcel={exportToExcel} translations={translations} />
          </> : null
        }
      </div>
      <div>
        <GapSitesAlertsHouseMap selectedPostcode={selectedPostcode} setSelectedUPRN={setSelectedUPRN} selectedOutward={selectedOutward} translations={translations} />
      </div>
    </div>
    <div style={{display: 'flex', flexDirection: 'column', gap: 15}}>
        <div>
          {/* <GapSitesAlertsVOAEvidence uprnEvidence={uprnEvidence} /> */}
          <GapSitesAlertsAddressEvidenceTable uprnEvidence={uprnEvidence} setUprnEvidence={setUprnEvidence} selectedUPRN={selectedUPRN} exportToExcel={exportToExcel} selectedPostcode={selectedPostcode} translations={translations} />
        </div>
        {/* <div style={{display: 'flex', gap: 15}}>
          <GapSitesAlertsOccupierEvidenceLeft uprnEvidence={uprnEvidence} />
          <GapSitesAlertsOccupierEvidenceRight uprnEvidence={uprnEvidence} />
        </div> */}
      </div>
  </GenericPageContainer>;
}
