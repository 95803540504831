import React, { useRef, useState, useEffect, useCallback } from 'react'
import ReactModal from 'react-modal';
import request from '../../helpers/request'
import endpoints from '../../helpers/endpoints';
import './twilio.css'

import { Device } from 'twilio-client' 
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import WaveSurfer from 'wavesurfer.js';

export default function Twilio({module, accountNumber, customer = {}, updateCustomer, customerNumber, updateCustomerNumber, height, surveyEndpoint}) {
    const [device, setDevice] = useState();
    const [call, setCall] = useState();
    const [user, setUser] = useState({user: null, company: null});
    const [volume, setVolume] = useState({inputColour: null, outputColour: null, inputVolume: null, outputVolume: null});
    const [callStatus, setCallStatus] = useState('closed');
    const [alternateAddresses, setAlternateAddresses] = useState();
    const [previousCalls, setPreviousCalls] = useState();
    const [selectedRecording, setSelectedRecording] = useState();
    const [previousCallsModalIsOpen, setPreviousCallsModalIsOpen] = useState('null');
    const [editingNumber, setEditingNumber] = useState(false);
    const tones = [
        [697, 1209],
        [697, 1336],
        [697, 1477],
        [770, 1209],
        [770, 1336],
        [770, 1477],
        [852, 1209],
        [852, 1336],
        [852, 1477],
        [941, 1209],
        [941, 1336],
        [941, 1477]
      ];
    const numberRef = useRef(null);

    useEffect(() => {
        request(true).get(endpoints.TWILIO_TOKEN).then((r) => {
            const device = new Device();
            device.setup(r.data.token);

            device.on('ready', () => {
                setDevice(device)
            });

            setUser({user: r.data.user, company: r.data.company})
        });

        setInterval(() => {
            request(true).get(endpoints.TWILIO_TOKEN).then((r) => {
                const device = new Device();
                device.setup(r.data.token);

                device.on('ready', () => {
                    setDevice(device)
                })

                setUser({user: r.data.user, company: r.data.company})
            })
        }, 3480000)
    }, [])

    useEffect(() => {
        request(true).get(`${endpoints.TWILIO_GET_RECORDINGS}?account_number=${accountNumber}`).then((r) => {
            if (r.status == 200) {
                setPreviousCalls(r.data.recordings);
            } else if (r.status == 204) {
                console.log("No prior calls");
            }
        }).catch(e => {
            console.error(e);
        })
      }, [accountNumber])

      useEffect(() => {
        if (!customer || Object.keys(customer).length === 0) {
            return
        }

        if (customer.AddressBase_Address) {
            request(true).get(endpoints.TWILIO_RTV_LOOKUP, {
                params: {
                    postcode: customer.AddressBase_Address.match(/[A-Za-z0-9]{1,4}\s[A-Za-z0-9]{1,4}$/)[0]
                }
            }).then(r => {
                setAlternateAddresses(r.data);
            }).catch(e => {
                //console.log(e);
            });
        }
      }, [customer])


    const makeCall = async (number) => {
        setEditingNumber(false);
        if (!device) {
            window.alert('Telephone service failed');
            return;
        }
        if (!number) {
            window.alert('Please select a number before making a call');
            return;
        }
        try {
            const params = {
                number: number,
                user: user.user,
                company_id: user.company,
                account_number: accountNumber,
                module: module,
            }
            const call = await device.connect(params);

            setCall(call);
            handleCallEvents(call);
        } catch (error) {
            window.alert('Call failed')
        }
    }

    const handleCallEvents = (call) => {
        call.on('volume', (inputVolume, outputVolume) => {
            var inputColour = "red";
            if (inputVolume < 0.5) {
                inputColour = "green";
            } else if (inputVolume < 0.75) {
                inputColour = "yellow";
            }

            var outputColour = "red";
            if (outputVolume < 0.5) {
                outputColour = "green";
            } else if (outputVolume < 0.75) {
                outputColour = "yellow";
            }
            setVolume({inputColour: inputColour, outputColour: outputColour, inputVolume: inputVolume, outputVolume: outputVolume})
        });

        call.on('cancel', (call) => {
            setCallStatus('closed');
        });
        call.on('disconnect', (call) => {
            setCallStatus('closed');
        });
        call.on('reject', (call) => {
            setCallStatus('closed');
        });
        call.on('accept', (call) => {
            setCallStatus('open');
        });
        
    }

    const muteInput = () => {
        if (call && call.isMuted() === false) {
            call.mute();
        } else if (call && call.isMuted() === true) {
            call.mute(false);
        }
    }

    const hangUp = () => {
        device.disconnectAll();
        setVolume({inputColour: null, outputColour: null, inputVolume: null, outputVolume: null});
        setCall(null);
    }

    const submitAnswers = () => {
        if (customer && accountNumber) {
            request(true).post(surveyEndpoint, {
                spid: accountNumber,
                customer: customer,
            }).then(r => r.status == 200 ? window.alert("Details successfully submitted") : window.alert("Details failed to submit") ).catch(e => {console.log(e)})
        } 
    }

    const setCustomerNumber = (event) => {
        if (event.target.id === "") {
            const input = event.target.value;
            if (input && !input.match(/^[\d\s]+$/)) {
                event.target.value = customerNumber
                return
            }
            if (input.match(/\d$/) && input?.length == (customerNumber?.length + 1 || 1)) {
                const tonesArray = tones[input.trim().substring(input.trim().length - 1)]
                playTone(tonesArray[0], tonesArray[1])
            }
            updateCustomerNumber(input);
            if(!input) setEditingNumber(false);
            return
        }

        let number = event.target.id;

        updateCustomerNumber(customerNumber ? customerNumber + number : number);
        
        let tone1 = event.target.getAttribute('tone1');
        let tone2 = event.target.getAttribute('tone2');

        playTone(tone1, tone2)
    }

    const gatherDTMF = (event) => {
        let number = event.target.id;

        call.sendDigits(number);
    }

      const playTone = (tone1, tone2) => {
        const audioContext = new AudioContext();
        const oscillator1 = audioContext.createOscillator();
        const oscillator2 = audioContext.createOscillator();

        const filterNode = audioContext.createBiquadFilter();
        filterNode.type = 'lowpass';
        filterNode.frequency.value = 8000;

        const gainNode1 = audioContext.createGain();
        const gainNode2 = audioContext.createGain();

        gainNode1.gain.setValueAtTime(0.1, audioContext.currentTime);
        gainNode2.gain.setValueAtTime(0.1, audioContext.currentTime);

        oscillator1.frequency.setValueAtTime(tone1, audioContext.currentTime);
        oscillator2.frequency.setValueAtTime(tone2, audioContext.currentTime);

        oscillator1.connect(gainNode1)
        oscillator2.connect(gainNode2)

        gainNode1.connect(filterNode);
        gainNode2.connect(filterNode);

        filterNode.connect(audioContext.destination);

        oscillator1.start();
        oscillator2.start();

        setTimeout(() => {
            oscillator1.stop();
            oscillator2.stop();
        }, 300)
      }

    return [
        <div className='twilio-call-block' style={{height: height ? `${height}px` : '100vh'}}>
            { customerNumber || editingNumber ? 
                <p style={{margin: '10px 0px 20px 0px'}}>You are calling { customer && customer.Company_Name ? 
                    <><strong style={{margin: '0px'}}>{ customer.Company_Name }</strong> at </> 
                : 
                    null 
                }
                { editingNumber ?
                    <input autoFocus className='dial-pad-number' ref={numberRef} value={customerNumber} onChange={(event) => setCustomerNumber(event)} onBlur={() => setEditingNumber(false)} style={{margin: '0px'}}></input>
                    :
                    <strong onClick={() => setEditingNumber(true)} style={{margin: '0px'}}> {customerNumber}</strong>
                } </p> 
            : 
                    <p onClick={() => setEditingNumber(true)}>No number selected</p>
            }
            <div style={{display: 'grid', gridTemplateColumns: '1fr', marginBottom: '10px', gap: '10px', textAlign: 'start'}}>
                <button className='button compact background-primary colour-white' onClick={() => setPreviousCallsModalIsOpen('true')}>See previous calls</button>
            </div>
            <div className="dial-pad" style={{margin: '0px 25% 10px'}}>
                { tones.map((numberTones, index) => {
                    let number
                    if (index === 9) {
                        number = '*'
                    } else if (index === 10) {
                        number = '0'   
                    }else if (index === 11) {
                        number = '#'
                    } else {
                        number = index + 1
                    }
                    
                    return (
                        <button className="number" id={number} tone1={numberTones[0]} tone2={numberTones[1]} onClick={(event) => 
                            callStatus === "open" ? gatherDTMF(event) : setCustomerNumber(event)
                        }>{number}</button>
                    )
                })}
                <button onClick={() => updateCustomerNumber(null)} style={{display: 'grid', gridColumnEnd: 2}} className="fa-regular fa-xmark number"></button>
                <button onClick={() => customerNumber ? updateCustomerNumber(customerNumber.slice(0, -1)) : null} style={{display: 'grid', gridColumnEnd: 4}} className="fa-regular fa-delete-left number"></button>
            </div>
            <div className='grid grid-columns-3 grid-gap-5'>
                <button style={callStatus === "open" || !customerNumber ? {backgroundColor: '#ececec'} : {backgroundColor: 'var(--primary)'} } className='button compact colour-white' onClick={() => callStatus === "closed" ? makeCall(customerNumber) : null}>Call</button>
                <button style={callStatus === "closed" ? {backgroundColor: '#ececec'} : {backgroundColor: 'var(--primary)'}} className='button compact colour-white' onClick={() => call ? muteInput() : null}>Mute</button>
                <button style={callStatus === "closed" ? {backgroundColor: '#ececec'} : {backgroundColor: '#f14014'} } className='button compact colour-white' onClick={() => callStatus === "open" ? hangUp() : null}>Hangup</button>
            </div>
            <p>Did you confirm the address?</p>
            <div className='grid grid-columns-2 grid-gap-5'>
                <button style={customer && [1, '1'].includes(customer.addressConfirmed) ? {border: '1.5px black solid', backgroundColor: 'var(--primary)'} : {backGroundColor: '#ececec'}} className='button button-no-focus compact colour-white' onClick={() => customer ? updateCustomer({...customer, addressConfirmed: [1, '1'].includes(customer.addressConfirmed) ? null : 1}) : null}>Yes</button>
                <button style={customer && [0, '0'].includes(customer.addressConfirmed)? {border: '1.5px black solid', backgroundColor: 'var(--primary)'} : {backGroundColor: '#ececec'}} className='button button-no-focus compact colour-white' onClick={() => customer ? updateCustomer({...customer, addressConfirmed: [0, '0'].includes(customer.addressConfirmed) ? null : 0}) : null}>No</button>
            </div>
            <p>Is the address correct?</p>
            <div className='grid grid-columns-3 grid-gap-5'>
                <button style={customer && customer.clientAddressCorrect === (1 || '1')? {border: '1.5px black solid', backgroundColor: 'var(--primary)'} : {backGroundColor: '#ececec'}} className='button button-no-focus compact colour-white' onClick={() => customer ? updateCustomer({...customer, clientAddressCorrect: [1, '1'].includes(customer.clientAddressCorrect) ? null : 1}) : null}>Client Address</button>
                <button style={customer && customer.UPRNAddressCorrect === (1 || '1')? {border: '1.5px black solid', backgroundColor: 'var(--primary)'} : {backGroundColor: '#ececec'}} className='button button-no-focus compact colour-white' onClick={() => customer ? updateCustomer({...customer, UPRNAddressCorrect: [1, '1'].includes(customer.UPRNAddressCorrect) ? null : 1}) : null}>UPRN Address</button>
                <button style={customer && customer.voaAddressCorrect === (1 || '1')? {border: '1.5px black solid', backgroundColor: 'var(--primary)'} : {backGroundColor: '#ececec'}} className='button button-no-focus compact colour-white' onClick={() => customer ? updateCustomer({...customer, voaAddressCorrect: [1, '1'].includes(customer.voaAddressCorrect) ? null : 1}) : null}>VOA Address</button>
            </div>
            <p>If no, what is the correct address?</p>
            <div className='grid grid-columns-1 grid-gap-5'>
                <select value={customer ? customer.correctAddress : null} onChange={_ => updateCustomer({...customer, correctAddress: _.target.value})} style={{width: '100%', height: '28px', border: '1px solid'}}>
                    {
                        alternateAddresses ? 
                            <>
                                <option value="Not available">Not available</option>
                                { alternateAddresses.map(address => {
                                    return (
                                        <option value={address.label}>{address.label}</option>
                                    )
                                })}
                            </>
                        : <option value="No address found">No addresses found</option>
                    }
                </select>
            </div>
            <p>Is the company name correct?</p>
            <div className='grid grid-columns-2 grid-gap-5'>
                <button style={customer && [1, '1'].includes(customer.occupierNameCorrect) ? {border: '1.5px black solid', backgroundColor: 'var(--primary)'} : {backGroundColor: '#ececec'}} className='button button-no-focus compact colour-white' onClick={() => customer ? updateCustomer({...customer, occupierNameCorrect: [1, '1'].includes(customer.occupierNameCorrect) ? null : 1}) : null}>Yes</button>
                <button style={customer && [0, '0'].includes(customer.occupierNameCorrect) ? {border: '1.5px black solid', backgroundColor: 'var(--primary)'} : {backGroundColor: '#ececec'}} className='button button-no-focus compact colour-white' onClick={() => customer ? updateCustomer({...customer, occupierNameCorrect: [0, '0'].includes(customer.occupierNameCorrect) ? null : 0}) : null}>No</button>
            </div>
            <p>If no, what is the correct company name?</p>
            <div className='grid grid-columns-1 grid-gap-5'>
                <input value={customer && customer.correctName ? customer.correctName : ''} onChange={_ => updateCustomer({...customer, correctName: _.target.value})} style={{width: '100%', justifySelf: 'center'}}></input>
            </div>
            <p>Did the company confirm a move in date?</p>
            <div className='grid grid-columns-2 grid-gap-5'>
                <button style={customer && [1, '1'].includes(customer.moveInDateConfirmed) ? {border: '1.5px black solid', backgroundColor: 'var(--primary)'} : {backGroundColor: '#ececec'}} className='button button-no-focus compact colour-white' onClick={() => customer ? updateCustomer({...customer, moveInDateConfirmed: [1, '1'].includes(customer.moveInDateConfirmed) ? null : 1}) : null}>Yes</button>
                <button style={customer && [0, '0'].includes(customer.moveInDateConfirmed) ? {border: '1.5px black solid', backgroundColor: 'var(--primary)'} : {backGroundColor: '#ececec'}} className='button button-no-focus compact colour-white' onClick={() => customer ? updateCustomer({...customer, moveInDateConfirmed: [0, '0'].includes(customer.moveInDateConfirmed) ? null : 0}) : null}>No</button>
            </div>
            <p>Move in date:</p>
            <div className='grid grid-columns-1 grid-gap-5 date-picker-block'>
                <DatePicker showYearDropdown yearDropdownItemNumber={100} scrollableYearDropdown maxDate={new Date()} showIcon selected={customer && customer.moveInDate ? typeof customer.moveInDate === "string"  ? Date.parse(customer.moveInDate) : customer.moveInDate : null} onChange={(date) => updateCustomer({ ...customer, moveInDate: date})}></DatePicker>
            </div>
            <p>Who did you speak to?</p>
            <div className='grid grid-columns-1 grid-gap-5'>
                <input value={customer && customer.callRecipient ? customer.callRecipient : ''} onChange={_ => updateCustomer({...customer, callRecipient: _.target.value})} style={{width: '100%', justifySelf: 'center'}}></input>
            </div>
            <p>Send for feedback?</p>
            <div className='grid grid-columns-2 grid-gap-5'>
                <button style={customer && [1, '1'].includes(customer.sendForFeedback) ? {border: '1.5px black solid', backgroundColor: 'var(--primary)'} : {backGroundColor: '#ececec'}} className='button button-no-focus compact colour-white' onClick={() => customer ? updateCustomer({...customer, sendForFeedback: [1, '1'].includes(customer.sendForFeedback) ? null : 1}) : null}>Yes</button>
                <button style={customer && [0, '0'].includes(customer.sendForFeedback) ? {border: '1.5px black solid', backgroundColor: 'var(--primary)'} : {backGroundColor: '#ececec'}} className='button button-no-focus compact colour-white' onClick={() => customer ? updateCustomer({...customer, sendForFeedback: [0, '0'].includes(customer.sendForFeedback) ? null : 0}) : null}>No</button>
            </div>
            <p>Comment Box:</p>
            <div style={{height: '6em'}} className='grid grid-columns-1 grid-gap-5'>
                <textarea value={customer && customer.feedback  ? customer.feedback : ''} onChange={_ => updateCustomer({...customer, feedback: _.target.value})} style={{width: '100%', justifySelf: 'center', resize: 'none', padding: '3px'}}></textarea>
            </div>
            <p>Desktop assessment completed?</p>
            <div className='grid grid-columns-2 grid-gap-5'>
                <button style={customer && [1, '1'].includes(customer.desktopAssessmentCompleted) ? {border: '1.5px black solid', backgroundColor: 'var(--primary)'} : {backGroundColor: '#ececec'}} className='button button-no-focus compact colour-white' onClick={() => customer ? updateCustomer({...customer, desktopAssessmentCompleted: [1, '1'].includes(customer.desktopAssessmentCompleted) ? null : 1}) : null}>Yes</button>
                <button style={customer && [0, '0'].includes(customer.desktopAssessmentCompleted) ? {border: '1.5px black solid', backgroundColor: 'var(--primary)'} : {backGroundColor: '#ececec'}} className='button button-no-focus compact colour-white' onClick={() => customer ? updateCustomer({...customer, desktopAssessmentCompleted: [0, '0'].includes(customer.desktopAssessmentCompleted) ? null : 0}) : null}>No</button>
            </div>
            <p>Assessment Notes:</p>
            <div className='grid grid-columns-1 grid-gap-5'>
                <input value={customer && customer.assessmentNotes ? customer.assessmentNotes : ''} onChange={_ => updateCustomer({...customer, assessmentNotes: _.target.value})} style={{width: '100%', justifySelf: 'center'}}></input>
            </div>
            <div>
                <button style={!customer ? {backgroundColor: "#ececec"} : null} className='button compact background-primary colour-white' onClick={(() => submitAnswers())}>Submit</button>
            </div>
            { device ? 
                <div>
                    <div style={{display: 'grid', gridTemplateColumns: '1fr', marginBottom: '10px', gap: '10px'}}>
                        <p style={{margin: '0px'}}>Input Devices</p>
                        <select onChange={_ => device.audio.setInputDevice(_.target.value)}>
                            {
                                Array.from(device.audio.availableInputDevices.entries()).map(([key, device]) => (
                                    <option key={key} value={key}>{device.label}</option>
                                ))
                            }
                        </select>
                        <p style={{margin: '0px'}}>Output Devices</p>
                        <select onChange={_ => device.audio.speakerDevices.set(_.target.value)}>
                            {
                                Array.from(device.audio.availableOutputDevices.entries()).map(([key, device]) => (
                                    <option key={key} value={key}>{device.label}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', marginBottom: '10px', gap: '10px', textAlign: 'start'}}>
                        <p style={{margin: '0px'}}>Mic Volume {call && call.isMuted() ? <i class="fa-solid fa-volume-xmark"></i> : <i class="fa-solid fa-volume-high"></i>}</p>
                        <div style={{width: call ? Math.floor(volume.inputVolume * 300) + 'px' : '0px', background: volume.inputColour}}></div>
                        <p style={{margin: '0px'}}>Speaker Volume</p>
                        <div style={{width: call ? Math.floor(volume.outputVolume * 300) + 'px' : '0px', background: volume.outputColour}}></div>
                    </div>
                </div>
                :
                null
            }
        </div>,
        <ReactModal
        className="card bulk-buy"
        isOpen={previousCallsModalIsOpen !== 'null'}> 
            <div style={{textAlign: 'center'}}>
                <select style={{height: 28, marginTop: 7, border: '1px solid var(--primary)', borderRadius: 5, width: "100%"}} value={selectedRecording ? selectedRecording : previousCalls ? previousCalls[0] : null} onChange={_ => setSelectedRecording(_.target.value)}>
                    { previousCalls && previousCalls.length ?
                        previousCalls.map(call => {
                            return (
                                <option value={call}>{call}</option>
                            )
                        })
                        :
                        <option>No previous calls found</option>
                    }
                </select>
            </div>
            <WaveSurferPlayer
                height={100}
                waveColour="rgb(200, 0, 200)"
                url={
                    previousCalls ?
                        `${sessionStorage.getItem('api-path')}${endpoints.TWILIO_DOWNLOAD_RECORDING}/${selectedRecording ? selectedRecording : previousCalls[0] }`
                    :
                        null
                }
            />
            <button style={{float: 'right', marginTop: "10%"}} className='button compact background-primary colour-white' onClick={() => setPreviousCallsModalIsOpen('null')}>Close</button>
        </ReactModal>
    ]
}

export function WaveSurferPlayer(props) {
    const containerRef = useRef()
    const [isPlaying, setIsPlaying] = useState(false)
    const [currentTime, setCurrentTime] = useState(0)
    const [isLoaded, setIsLoaded] = useState(false)
    const [errorMsg, setErrorMsg] = useState(!props.url ? "No file found" : null)
    const wavesurfer = useWavesurfer(containerRef, props)

    function useWavesurfer(containerRef, options) {
        const [wavesurfer, setWavesurfer] = useState(null)

        useEffect(() => {
            setIsLoaded(false)
            if (!containerRef.current) return
            
            const ws = WaveSurfer.create({
                ...options,
                container: containerRef.current
            })

            setWavesurfer(ws)

            return () => {
                ws.destroy()
            }
        }, [options, containerRef])

        return wavesurfer
    }

    const onPlayClick = useCallback(() => {
        wavesurfer.isPlaying() ? wavesurfer.pause() : wavesurfer.play()
    }, [wavesurfer])

    const timeAhead15Seconds = () => {
        wavesurfer.setTime(currentTime + 15)
    }

    const timeBack15Seconds = () => {
        wavesurfer.setTime(currentTime - 15)
    }

    useEffect(() => {
        if (!wavesurfer) return

        setCurrentTime(0)
        setIsPlaying(false)

        const subscriptions = [
            wavesurfer.on('play', () => setIsPlaying(true)),
            wavesurfer.on('pause', () => setIsPlaying(false)),
            wavesurfer.on('timeupdate', (currentTime) => setCurrentTime(currentTime)),
            wavesurfer.on('ready', () => setIsLoaded(true))
        ]

        return () => {
            subscriptions.forEach((unsub) => unsub())
        }
    }, [wavesurfer])

    return (
            <>
                <div ref={containerRef} style={{ minHeight: '120px' }}></div>
                {
                    isLoaded ? 
                        <div className='playback-controls'>
                            <i onClick={timeBack15Seconds} class="fa-solid fa-backward-step"></i>
                            { isPlaying ? <i onClick={onPlayClick} class="fa-solid fa-pause"></i> : <i onClick={onPlayClick} class="fa-solid fa-play"></i> }
                            <i onClick={timeAhead15Seconds} class="fa-solid fa-forward-step"></i>
                        </div>
                    :
                        errorMsg ? 
                            <>
                                <p>{errorMsg}</p>
                            </>
                            :
                            <>
                                <i style={{ marginTop: '10px' }} className='fas fa-loader fa-fw fa-spin fa-2x'></i>
                            </>
                }
            </>
    )
}