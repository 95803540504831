import React from "react";
import Navigation from "../../../components/navigation/navigation";
import Container from "../../../components/container/container";
import Footer from "../../../components/footer/footer";
import { ReactComponent as HouseIcon } from '../../../assets/House Icon.svg'
import "./voidCOTAlerts.scss";
import { Link, useHistory } from "react-router-dom";
import request from "../../../helpers/request";
import endpoints from "../../../helpers/endpoints";
import {
  BarChart,
  XAxis,
  YAxis,
  Bar,
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
  LabelList,
} from "recharts";
import session from "../../../stores/session";
import { useState } from "react";
import { useEffect } from "react";
import PageTitle from "../../../components/page-header/pageTitle";

export default function VoidCOTAlertsReport() {
  const history = useHistory();

  const [date, setDate] = useState(null);
  const [dates, setDates] = useState([]);

  const [chartData, setChartData] = useState(null);
  const [displayData, setDisplayDate] = useState(null)

  const localised = Intl.NumberFormat("en-GB");

  const getChartData = useEffect(() => {
    if (!date) return
    console.log('got date',date)
    request(true)
      .get(endpoints.VOID_COT_ALERTS_SUMMARY_WATERFALL + `?date=${date}`)
      .then((e) => {
        if (e.status === 200) {
          setChartData(e.data);
        }
      });
  }, [date]);

  const getDates = () => {
    request(true)
      .get(endpoints.VOID_COT_ALERTS_SUMMARY_WATERFALL_DATES)
      .then((r) => {
        if (r.status === 200) {
          setDates(r.data.dates);
          setDate(r.data.dates[0].raw);

          getChartData();
        }
      })
      .catch((e) => {
        //console.log(e);
      });
  };

  useEffect(() => {
    getDates();
  }, []);

  useEffect(() => {
    if (!chartData) return
    setDisplayDate([
      {
        'name': 'Occupier (High and Low)',
        uv: chartData.Occupier_H_L,
        value: localised.format(chartData.Occupier_H_L),
        lv: 0,
        'fill': 'var(--teal)'
      },
      {
        'name': 'Occupier (No Active Data)',
        uv: chartData.Occupier_No_D,
        value: localised.format(chartData.Occupier_No_D),
        lv: chartData.Occupier_H_L,
        'fill': 'var(--blue)'
      },
      {
        'name': 'Deceased',
        uv: chartData.Deceased,
        value: localised.format(chartData.Deceased),
        lv: chartData.Occupier_No_D + chartData.Occupier_H_L,
        'fill': 'var(--blue)'
      },
      {
        'name': 'Gone Away',
        uv: chartData.Gone_away,
        value: localised.format(chartData.Gone_away),
        lv: chartData.Occupier_No_D + chartData.Occupier_H_L + chartData.Deceased,
        'fill': 'var(--blue)'
      },
      {
        'name': 'Previous Occupier',
        uv: chartData.Prev_occ,
        value: localised.format(chartData.Prev_occ),
        lv: chartData.Occupier_No_D + chartData.Occupier_H_L + chartData.Deceased + chartData.Gone_away,
        'fill': 'var(--blue)'
      },
      {
        'name': 'Occupier Activity',
        uv: chartData.Occ_activity,
        value: localised.format(chartData.Occ_activity),
        lv: 0,
        'fill': 'var(--teal)'
      },
      {
        'name': 'Property Not Yet Built',
        uv: chartData.NYB,
        value: localised.format(chartData.NYB),
        lv: chartData.Occ_activity,
        'fill': 'var(--yellow)'
      },
      {
        'name': 'No Occupier Found',
        uv: chartData.NOF,
        value: localised.format(chartData.NOF),
        lv: chartData.Occ_activity + chartData.NYB,
        'fill': 'var(--yellow)'
      },
      {
        'name': 'Total Void Base',
        uv: chartData.TVB,
        value: localised.format(chartData.TVB),
        lv: 0,
        'fill': 'var(--cobalt)'
      },
    ])
  }, [chartData])

  return (
    <div className="grid grid-gap-15" id="void-sales-alerts-page">
      <Navigation />
      <Container>
        <PageTitle title={ history.location.pathname.indexOf('void') !== -1 ? 'Void COT Alerts - Report' : 'Occupier COT Alerts - Report' } titleRight={
            <div style={{ display: "flex" }}>
            <Link className="navigate" to={ history.location.pathname.indexOf('void') !== -1 ? '/portal/void-cot-alerts/' : '/portal/occupier-cot-alerts/' }>
                Overview
            </Link>
            <Link className="navigate" to={ history.location.pathname.indexOf('void') !== -1 ? '/portal/void-cot-alerts/search' : '/portal/occupier-cot-alerts/search' }>
                Search
            </Link>
            { (session.user.permissions.indexOf('VOID_COT-WORKFLOW') !== -1) ?
                <Link className="navigate" to={ history.location.pathname.indexOf('void') !== -1 ? '/portal/void-cot-alerts/workflow' : '/portal/occupier-cot-alerts/workflow' }>
                    Workflow
                </Link>
            : null }
            { (session.user.permissions.indexOf('VOID_COT-WORKFLOW-COMBINED') !== -1) ?
                <Link className="navigate" to={ this.props.history.location.pathname.indexOf('void') !== -1 ? '/portal/void-cot-alerts/workflow-combined' : '/portal/occupier-cot-alerts/workflow-combined' }>
                    Workflow Combined
                </Link>
            : null }
            { (session.user.permissions.indexOf('VOID_COT-NFI-SEARCH') !== -1) ?
                <Link className="navigate" to={ history.location.pathname.indexOf('void') !== -1 ? '/portal/void-cot-alerts/nfi' : '/portal/occupier-cot-alerts/search' }>
                    NFI Search
                </Link>
            : null }
          </div>
        } />
      </Container>
      <Container>
        <div className="ov">
          <div className="grid-overview">
            <div className="left" style={{gridColumnEnd: 3, gridColumnStart: 1}}>
              <div className="data-row file-date">
                <div className="data-row-title">File Date</div>
                <div className="data-row-field">
                  <select
                    onChange={(e) => setDate(e.target.value)}
                    value={date}
                  >
                    {dates.map((item) => {
                      return (
                        <option key={item.raw} value={item.raw}>
                          {item.pretty}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="vcv-table-or-graph" style={{minHeight: 500, gridColumnEnd: 2}}>
                {chartData ? (
                  <>
                    <ResponsiveContainer>
                      <BarChart
                        data={displayData}
                        margin={{ bottom: 20, top: 20 }}
                        barSize={50}
                      >
                        <XAxis dataKey="name" interval={0} tick={{fontSize: 15, width: 130}} />
                        {/* <Legend iconType="circle" iconSize={11} /> */}
                        <YAxis
                          type="number"
                          allowDecimals={false}
                          tick={{ fontSize: 10 }}
                          tickFormatter={(item) => {
                            return localised.format(item);
                          }}
                        />
                        <CartesianGrid />
                        <Bar dataKey="lv" stackId="a" fill="transparent" label="a" style={{fill: 'none'}} />
                        <Bar label="Value" dataKey="uv" stackId="a" fill="#82ca9d">
                          <LabelList dataKey="value" position="top" />
                        </Bar>
                        <Tooltip
                          cursor={false}
                          formatter={(value, name, props) => {
                            return [localised.format(value), "Value"];
                          }}
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </div>
  );
}
