import React, { Component } from 'react'
import './navigation.scss';
import logo from '../../assets/logo.svg';
import { NavLink, Link, withRouter } from 'react-router-dom';
import Select from 'react-select'
import request from '../../helpers/request'
import endpoints from '../../helpers/endpoints'
import Modal from 'react-modal'

import sessionState from '../../stores/session';
import session from '../../stores/session';

export default withRouter(class Navigation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menuOpen: false,
            telescopeUrl: sessionStorage.getItem('api-path') + `/loadtelescope?token=${sessionStorage.getItem('user-token')}`,
            logoUrl: sessionStorage.getItem('logo-url') || logo,
            error: false,
            new_user: {
                name: '',
                email: '',
            },
            companies: [],
            errors: {},
            draggedBookmarkIndex: -1,
        }
    }

    toggleMenu = () => {
        this.setState({
            menuOpen: !this.state.menuOpen
        });
    }

    closeModal = () => {
        this.setState({
            modalIsOpen: false,
        })
    }

    openModal = () => {
        this.setState({
            modalIsOpen: true,
        })
    }

    updateNewUserFormValue = (e) => {
        this.setState({
            new_user: {
                ...this.state.new_user,
                [e.target.name]: e.target.value ? e.target.value : undefined
            }
        })
    }

    newUserFormSubmit = () => {
        this.state.new_user.company_id = session.company.id
        request(true).post(endpoints.COMPANY_ADMIN_USERS, {
            ...this.state.new_user,
        }).then(r => {
            this.setState({
                new_user: { name: '', email: ''}
            });
            this.props.history.push(`/admin/portal/users/${r.data.user_id}`)
        }).catch(r => {
            if(r.response.data.error == "User email already in use") {
                window.alert("User email already in use");
                this.setState({
                    new_user: { name: '', email: ''}
                });
            } else {
                this.setState({
                    errors: r.response.data.errors
                });
            }
        });
    }

    onDragStart = (event, index) => {
        this.setState({ draggedBookmarkIndex: index })
    }

    onDragOver = (event) => {
        event.preventDefault();
    }

    onDrop = (event) => {
        event.preventDefault();

        const targetBookmarkIndex = Number(event.target.getAttribute('data-index'));
        const draggedBookmarkIndex = this.state.draggedBookmarkIndex;

        if (draggedBookmarkIndex !== -1 && draggedBookmarkIndex !== targetBookmarkIndex) {
            const updatedBookmarks = [...session.user.bookmarks];
            const draggedBookmark = updatedBookmarks.splice(draggedBookmarkIndex, 1)[0];
            updatedBookmarks.splice(targetBookmarkIndex, 0, draggedBookmark);

            updatedBookmarks.forEach((bookmark, index) => {
                bookmark.order = index + 1
            });

            request(true).post(endpoints.SET_BOOKMARK_ORDER, {
                bookmarks: updatedBookmarks,
            }).then(r => {
                session.fetch();
            })
        }
    }

    render() {
        return (
            <div className="navigation">
                <div className="left">
                    { sessionState.token ?
                        (sessionState.company ?
                            <Link to="/portal"><img src={this.state.logoUrl} alt="Logo" /></Link>
                        :
                            <Link to="/admin/portal"><img src={logo} alt="Logo" /></Link>
                        )
                    :
                        <Link to="/login"><img src={this.state.logoUrl} alt="Logo" /></Link>
                    }
                    <small>
                        { process.env.NODE_ENV !== "production" ? sessionStorage.getItem('frontend-name') : null }
                    </small>
                </div>
                {/* <div className="bookmarks">
                    {
                        session.user.bookmarks ? session.user.bookmarks.map((bookmark, index) => {
                            return(
                                <Link key={index} data-index={index} draggable={true} 
                                    onDragStart={event => this.onDragStart(event, index)} 
                                    onDragOver={this.onDragOver} 
                                    onDrop={this.onDrop} 
                                    onDragEnd={() => this.setState({ draggedBookmarkIndex: -1})} 
                                    style={{ opacity: this.state.draggedBookmarkIndex === index ? 0.25 : 1 }}
                                    to={bookmark.url}>{bookmark.pageName}</Link>
                            )
                        })
                        : null
                    }
                </div> */}
                <div className="right">
                    {
                        sessionState.token ? <>
                            <div className={ "user " + (this.state.menuOpen ? 'menu-open' : 'menu-closed') } onClick={this.toggleMenu}>
                                <div className="font-weight-500 colour-white">
                                    <p>{ session.user ? session.user.name : '' } </p>
                                    <div>
                                        <i className="far fa-chevron-left"></i>
                                    </div>
                                    <div className="account-menu">
                                        <Link to="/account">Account</Link>
                                        {
                                            !sessionState.company ? <a target="_blank" href={this.state.telescopeUrl}>Telescope</a> : null
                                        }
                                        {
                                            session.user.permissions.indexOf('ADMIN-CREATE-USER') !== -1 ?
                                                <Link onClick={this.openModal}>Add User</Link>
                                            : null
                                        }
                                        {/* <Link to='/version-history'>Release Notes</Link> */}
                                        <Link to="/logout">Log Out</Link>
                                    </div>
                                </div>
                            </div>
                        </> : <>
                            <NavLink to="/login">Sign In</NavLink>
                        </>
                    }
                </div>
                <Modal
                        style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}
                        className="card"
                        isOpen={this.state.modalIsOpen}
                        onRequestClose={this.closeModal}
                        contentLabel="Add A User"
                        appElement={document.getElementById('company-page')}
                    >
                    <div className="grid grid-gap-15">
                            <div className="form-row">
                                <label htmlFor="name">Name:</label>
                                <input onChange={this.updateNewUserFormValue} name="name" placeholder="Name" className="input" type="text" value={this.state.new_user.name} required/>
                                { this.state.errors.name ? this.state.errors.name.map((value, index) => {
                                    return (
                                        <p key={index}>
                                            { value }
                                        </p>
                                    )
                                }) : <></>}
                            </div>
                            <div className="form-row">
                                <label htmlFor="email">Email:</label>
                                <input onChange={this.updateNewUserFormValue} name="email" placeholder="Email" className="input" type="text" value={this.state.new_user.email} required/>
                                { this.state.errors.email ? this.state.errors.email.map((value, index) => {
                                    return (
                                        <p className="error" key={index}>
                                            { value }
                                        </p>
                                    )
                                }) : <></>}
                            </div>
                            <button onClick={this.newUserFormSubmit} className="button background-primary colour-white">Create</button>
                            <p className="text-center">{ this.state.message }</p>
                        </div>
                    </Modal>
            </div>
        )
    }
})
