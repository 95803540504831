import React, { Component } from 'react'
import Navigation from '../../../components/navigation/navigation'
import { ReactComponent as HouseIcon } from '../../../assets/House Icon.svg'
import Container from '../../../components/container/container'
import Footer from '../../../components/footer/footer'
import request from '../../../helpers/request'
import endpoints from '../../../helpers/endpoints'
import { view } from '@risingstack/react-easy-state'
import './previousOccupier.scss';

import { Link, withRouter } from 'react-router-dom'
import session from '../../../stores/session'
import HelpVideo from '../../../components/help-video/helpVideo'
import useWSProduct from '../../../helpers/useWSProduct'
import PropertyMap from '../../../components/property-map/property-map'
import { GenericTable } from '../VacantCOTAlerts/sharedComponents'
import PageTitle from '../../../components/page-header/pageTitle'

export default view(
  withRouter(
    class PreviousOccupierNFIData extends Component {
      constructor(props) {
        super(props)

        this.state = {
          accountNumber: '',
          customer: null,
          showMap: true,
          purchasable: null,
          shouldRefreshMap: false,
          translations: null,
        }
      }

      componentDidMount = () => {
        useWSProduct('previous_occupier');// eslint-disable-line
        request(true).get('/translate?key=po.').then(r => {
          this.setState({
            translations: r.data
          });
        }).catch(e => {
          console.error(e);
        })
      }

      accountNumberChange = (event) => {
        this.setState({
          accountNumber: event.target.value
        });
      }

      getData = () => {
        request(true).get(`${endpoints.PREVIOUS_OCCUPIER_NFI_GET_CUSTOMER}?search=${this.state.accountNumber}`).then(r => {
          console.log(r.data.customer);
          if (!r.data.customer) window.alert('Account not found.');
          this.setState({
            customer: r.data.customer,
            showMap: true,
            purchasable: r.data.customer && r.data.customer.Date_Bought === null && r.data.customer.Occupier_Score > 0,
            shouldRefreshMap: true,
          }, () => {
            this.setState({ shouldRefreshMap: false });
          });
        }).catch(e => {
          //console.log(e);
        });
      }

      purchaseCustomer = () => {
        request(true).post(`${endpoints.PREVIOUS_OCCUPIER_NFI_BUY_CUSTOMER}`, {
          "customer": this.state.customer.Account_Number
        }).then(r => {
          this.setState({
            customer: r.data.customer,
            showMap: true,
            purchasable: r.data.customer.Date_Bought === null
          });
        }).catch(e => {
          console.log(e);
        })
      }

      searchKeyDown = (e) => {
        if (e.which === 13) {
          this.getData();
        }
      }

      componentWillUnmount() {
        useWSProduct('previous_occupier', 'unlisten');// eslint-disable-line
      }

      footprintTableLeft = [
        [ 'Council Tax', 'NFI_Council_Tax' ],
        [ 'Housing Benefit Claimants', 'NFI_Housing_Benefit_Claimants' ],
        [ 'Electoral Register', 'NFI_Electoral_Register' ],
        [ 'Housing Rents', 'NFI_Housing_Rents' ],
        [ 'State Benefits', 'NFI_State_Benefits' ],
        [ 'Residents Parking Permit', 'NFI_Residents_Parking_Permit' ],
        [ 'Student Loans', 'NFI_Student_Loans' ],
        [ 'Blue Badges', 'NFI_Blue_Badges' ],
      ]

      footprintTableRight = [
        [ 'Deferred Pensions', 'NFI_Deferred_Pensions' ],
        [ 'Lone Pensioner Allowance', 'NFI_Lone_Pensioner_Allowance' ],
        [ 'Occupational Pensions', 'NFI_Occupational_Pensions' ],
        [ 'Pensions Gratuities', 'NFI_Pensions_Gratuities' ],
        [ 'Concessionary Travel Pass', 'NFI_Concessionary_Travel_Pass' ],
        [ 'Other', 'NFI_Other' ],
        [ 'Earliest Date Linked to Property', 'NFI_Earliest_date_linked_to_property' ],
        [ 'Occupier Score', 'NFI_Occupier_Score' ],
      ]

      footprintTableOptions = {
        biggerSecondRow: false,
        title: 'Occupier Footprint'
      }

      render() {
        return (
          <div className="grid grid-gap-15" id="previous-occupier-page">
            <Navigation />
            <Container>
              <PageTitle title="Previous Occupier - NFI Data" titleRight={
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Link className="navigate" to={ '/portal/previous-occupier/' }>
                    To Overview
                  </Link>
                  {(session.user.permissions.indexOf('PREVIOUS_OCCUPIER-WORKFLOW') !== -1) ?
                    <Link className="navigate" to={ '/portal/previous-occupier/workflow' }>
                      To Workflow
                    </Link>
                    : null}
                </div>
              } />
            </Container>
            <Container>
              <div className="vcv">
                <div className="primary-grid">
                  <div className="left-grid">
                    <div className="data-row">
                      <div className="data-row-title">
                        {this.state.translations ? this.state.translations['po.account_number'] : ''}
                      </div>
                      <input type="text" className="data-row-field" name="account-number" value={this.state.accountNumber} onChange={this.accountNumberChange} onKeyPress={this.searchKeyDown} />
                    </div>
                    {this.state.customer && this.state.customer.Surname !== false ?
                      <div className="name-grid">
                        <div className="data-row">
                          <div className="data-row-title">
                            Title
                          </div>
                          <input type="text" className="data-row-field" disabled={true} value={this.state.customer ? this.state.customer.NFI_Title ? `${this.state.customer.NFI_Title}` : '' : ''} />
                        </div>
                        <div className="data-row">
                          <div className="data-row-title">
                            Forename
                          </div>
                          <input type="text" className="data-row-field" disabled={true} value={this.state.customer ? `${this.state.customer.NFI_Forename}` : ''} />
                        </div>
                        <div className="data-row">
                          <div className="data-row-title">
                            Surname
                          </div>
                          <input type="text" className="data-row-field" disabled={true} value={this.state.customer ? `${this.state.customer.NFI_Surname}` : ''} />
                        </div>
                      </div>
                      :
                      <div className="data-row">
                        <div className="data-row-title">
                          {this.state.translations ? this.state.translations['po.name'] : ''}
                        </div>
                        <input type="text" className="data-row-field" disabled={true} value={this.state.customer ? `${this.state.customer.NFI_Title}` : ''} />
                      </div>
                    }
                    <div className="left-grid-bottom">
                      <div className="data-row">
                        <div className="data-row-title">
                          {this.state.translations ? this.state.translations['po.date_of_birth'] : ''}
                        </div>
                        <input type="text" className="data-row-field" disabled={true} value={this.state.customer ? this.state.customer.Date_of_Birth : ''} />
                      </div>
                      <div className="data-row">
                        <div className="data-row-title">
                          {this.state.translations ? this.state.translations['po.landline'] : ''}
                        </div>
                        <input type="text" className="data-row-field" disabled={true} value={this.state.customer ? this.state.customer.Landline : ''} />
                      </div>
                      <div className="data-row">
                        <div className="data-row-title">
                          {this.state.translations ? this.state.translations['po.mobile'] : ''}
                        </div>
                        <input type="text" className="data-row-field" disabled={true} value={this.state.customer ? this.state.customer.Mobile : ''} />
                      </div>
                    </div>
                    <div className="data-row">
                      <div className="data-row-title">
                        {this.state.translations ? this.state.translations['po.client_address'] : ''}
                      </div>
                      <input type="text" className="data-row-field" disabled={true} value={this.state.customer ? this.state.customer.SPID_Address : ''} />
                    </div>
                    <div className="data-row">
                      <div className="data-row-title">
                        {this.state.translations ? this.state.translations['po.matched_address'] : ''}
                      </div>
                      <input type="text" className="data-row-field" disabled={true} value={this.state.customer ? this.state.customer.AddressBase_Address : ''} />
                    </div>
                    <div className="left-grid-bottom">
                      <div className="data-row">
                        <div className="data-row-title">
                          {this.state.translations ? this.state.translations['po.address_score'] : ''}
                        </div>
                        <input type="text" className="data-row-field" disabled={true} value={this.state.customer ? this.state.customer.Address_Quality : ''} />
                      </div>
                      <div className="data-row">
                        <div className="data-row-title">
                          {this.state.translations ? this.state.translations['po.uprn'] : ''}
                        </div>
                        <input type="text" className="data-row-field" disabled={true} value={this.state.customer ? this.state.customer.UPRN : ''} />
                      </div>
                      <div className="data-row">
                        <div className="data-row-title">
                          {this.state.translations ? this.state.translations['po.new_connection'] : ''}
                        </div>
                        <input type="text" className="data-row-field" disabled={true} value={this.state.customer ? this.state.customer.New_Connection : ''} />
                      </div>
                    </div>
                  </div>
                  <div className="right-grid">
                    <button className="search-button" onClick={this.getData}>
                      Search
                    </button>
                    {(session.user.permissions.indexOf('PREVIOUS_OCCUPIER-PURCHASE') === -1 && this.state.purchasable) ?
                      <button className="buy-button disabled" disabled={true}>
                        Purchase Customer
                      </button>
                      :
                      <button className="buy-button" disabled={!this.state.purchasable} onClick={this.purchaseCustomer}>
                        {this.state.purchasable === true || this.state.purchasable === null ? "Purchase Customer" :
                          this.state.customer.Bought_By ?
                            `Purchased on ${this.state.customer.Date_Bought} by ${this.state.customer.Bought_By}`
                            :
                            this.state.customer.Date_Bought ?
                              `Purchased on ${this.state.customer.Date_Bought}`
                              :
                              'Unavailable for Purchase'
                        }
                      </button>
                    }
                    <div className="data-row">
                      <div className="data-row-title">
                        {this.state.translations ? this.state.translations['po.email'] : ''}
                      </div>
                      <input type="text" className="data-row-field" disabled={true} value={this.state.customer ? this.state.customer.Email : ''} />
                    </div>
                    <div className="data-row">
                      <div className="data-row-title">
                        {this.state.translations ? this.state.translations['po.void_age_band'] : ''}
                      </div>
                      <input type="text" className="data-row-field" disabled={true} value={this.state.customer ? this.state.customer.Voids_Age_Band : ''} />
                    </div>
                    <div className="data-row">
                      <div className="data-row-title">
                        {this.state.translations ? this.state.translations['po.account_type'] : ''}
                      </div>
                      <input type="text" className="data-row-field" disabled={true} value={this.state.customer ? this.state.customer.Type : ''} />
                    </div>
                    <div className="data-row">
                      <div className="data-row-title">
                        {this.state.translations ? this.state.translations['po.consumption'] : ''}
                      </div>
                      <input type="text" className="data-row-field" disabled={true} value={this.state.customer ? this.state.customer.Consumption : ''} />
                    </div>
                  </div>
                </div>
                <div className="secondary-grid">
                  <PropertyMap address={this.state.customer ? this.state.customer.SPID_Address : null} />
                  <GenericTable data={this.state.customer} rows={this.footprintTableLeft} {...this.footprintTableOptions} />
                  <GenericTable data={this.state.customer} rows={this.footprintTableRight} {...this.footprintTableOptions} />
                </div>
              </div>
            </Container>
            <HelpVideo video={session.company.vca_video_url} />
            <Footer />
          </div>
        )
      }
    }
  )
)

